import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const integrationService = createApi({
  reducerPath: "integrationService",
  tagTypes: "integration",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Integration
      createNewIntegration: builder.mutation({
        query: (data) => {
          return {
            url: "/api/create-integration",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["integration"],
      }),

      //   Update the Integration
      updateIntegration: builder.mutation({
        query: (updatedData) => {
          return {
            url: `/api/update-integration/${updatedData.id}`,
            method: "PATCH",
            body: updatedData,
          };
        },
        invalidatesTags: ["integration"],
      }),
      //   Delete a Integration
      deleteIntegration: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete-integration/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["integration"],
      }),

      //   Get the List of Integration
      getIntegrationsList: builder.query({
        query: () => {
          return {
            url: "/api/view-integration",
            method: "GET",
          };
        },
        providesTags: ["integration"],
      }),

      //   Get the Single Integration Data
      fetchIntegration: builder.query({
        query: (id) => {
          return {
            url: `/api/view-integration/${id}`,
            method: "GET",
          };
        },
        providesTags: ["integration"],
      }),
    };
  },
});

export const {
  useGetIntegrationsListQuery,
  useCreateNewIntegrationMutation,
  useDeleteIntegrationMutation,
  useFetchIntegrationQuery,
  useUpdateIntegrationMutation,
} = integrationService;

export default integrationService;
