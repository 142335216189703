import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../components/Loader";
import {
  useFetchIntegrationQuery,
  useUpdateIntegrationMutation,
} from "../../store/services/integrationService";
import { useGetIntegrationAccessQuery } from "../../store/services/integrationAccessService";

const UpdateIntegration = () => {
  // focus(".txtBox");
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: integrationAccessData,
    isLoading: integrationAccessLoading,
    isFetching: integrationAccessFetching,
  } = useGetIntegrationAccessQuery();

  const {
    data: integrationData,
    isLoading: integrationLoading,
    isFetching: integrationFetching,
  } = useFetchIntegrationQuery(id);

  const [helpIntegrationAccess, setHelpIntegrationAccess] = useState(false);
  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [createIntegration, setCreateIntegration] = useState({
    name: "",
    comment: "",
    status: "",
    number: "",
    link_api: "",
    website: "",
    integrationaccess: [],
  });

  const handleInput = (e) => {
    setCreateIntegration({
      ...createIntegration,
      [e.target.name]: e.target.value,
    });
  };

  const handleStatus = (e) => {
    setCreateIntegration({ ...createIntegration, status: e.value });
    setSelectedStatus({ display: e.display, value: e.value });
  };

  useEffect(() => {
    setHelpIntegrationAccess(createIntegration.integrationaccess);
  }, [createIntegration]);

  const handleIntegrationAccess = (e, item) => {
    // Destructuring
    const { value, checked } = e.target;
    // Case 1 : The user checks the box
    if (checked) {
      let finalSet = [];
      let duplicate = false;
      helpIntegrationAccess.forEach((element) => {
        finalSet.push(element);
        if (item.id === element.id) {
          duplicate = true;
        }
      });
      if (duplicate === false) {
        finalSet.push(item);
      }
      setCreateIntegration({
        ...createIntegration,
        integrationaccess: finalSet,
      });
    }
    // Case 2  : The user unchecked the box
    else {
      let newWorkDaysList = helpIntegrationAccess.filter(function (itemX) {
        return itemX.id !== item.id;
      });
      setCreateIntegration({
        ...createIntegration,
        integrationaccess: newWorkDaysList,
      });
    }
  };

  const [saveIntegration, data] = useUpdateIntegrationMutation();

  const submitCreateIntegration = (e) => {
    e.preventDefault();
    saveIntegration(createIntegration);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/dashboard/integrations");
    }
  }, [data?.isSuccess]);

  useEffect(() => {
    if (!integrationFetching) {
      setCreateIntegration(integrationData?.data);
      setSelectedStatus({
        display: integrationData?.data?.status,
        value: integrationData.data.status,
      });
    }
  }, [integrationData?.data]);

  return (
    <form onSubmit={submitCreateIntegration} className="new-integration flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Atualizar Integração</div>
          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome *</div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createIntegration.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                {!integrationFetching && (
                  <Select
                    defaultValue={selectedStatus}
                    onChange={handleStatus}
                    getOptionLabel={(option) => option.display}
                    getOptionValue={(option) => option.value}
                    options={statusData}
                    className="select"
                    id="status"
                    name="status"
                    placeholder="Selecionar Status"
                  />
                )}
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Website</div>
                <input
                  name="website"
                  type="url"
                  className="txt"
                  placeholder="https://google.com/"
                  value={createIntegration.website}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Link da API</div>
                <input
                  name="link_api"
                  type="url"
                  className="txt"
                  placeholder="https://google.com/"
                  value={createIntegration.link_api}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Número de Contato</div>
                <input
                  name="number"
                  type="number"
                  className="txt"
                  placeholder="(31)99999-9999"
                  value={createIntegration.number}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Comentário</div>
                <textarea
                  name="comment"
                  type="text"
                  className="txt h-[100px]"
                  placeholder="Comentário..."
                  value={createIntegration.comment}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="row1 mt-12">
              {!integrationAccessLoading && (
                <div className="field flex">
                  <div className="row6 w-full">
                    {integrationAccessData?.data.map((item, index) => (
                      <div className="item flex aic mr-7" key={index}>
                        <input
                          type="checkbox"
                          name="integrationaccess"
                          value={`${item.name}`}
                          onChange={(e) => handleIntegrationAccess(e, item)}
                          checked={
                            createIntegration?.integrationaccess?.filter(
                              (x) => x.id === item.id
                            ).length > 0
                          }
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">{item.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Atualizar"}
                className="btn button"
              />
            </div>
          </>
          {integrationFetching || integrationLoading ? <Loader /> : <></>}
        </div>
      </div>
    </form>
  );
};

export default UpdateIntegration;
