import React, { useState, useEffect } from "react";
import { CloseIcon } from "../../assets/icons";
import { useDeleteIntegrationMutation } from "../../store/services/integrationService";

const IntegrationDelete = ({ setOpen, data }) => {
  const [removeIntegration, response] = useDeleteIntegrationMutation();

  const deleteIntegration = (id) => {
    removeIntegration(id);
  };

  useEffect(() => {
    if (response?.data?.success === true) {
      setOpen(false);
    }
  }, [response?.data?.success === true]);

  return (
    <div className="delete-popup flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col justify-around">
          <div className="desc">
            Are you sure to delete ‘{data?.name}’ Integration?
          </div>
          <div className="actions flex aic jc">
            <div className="btn button" onClick={(e) => setOpen(false)}>
              Cancel
            </div>
            <div
              className="btn button"
              onClick={(e) => deleteIntegration(data?.id)}
            >
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationDelete;
