import React from "react";

function CategoryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M2 3.25A1.25 1.25 0 013.25 2H9.5a1.25 1.25 0 011.25 1.25v17.5A1.25 1.25 0 019.5 22H3.25A1.25 1.25 0 012 20.75V3.25zm11.25 0A1.25 1.25 0 0114.5 2h6.25A1.25 1.25 0 0122 3.25V9.5a1.25 1.25 0 01-1.25 1.25H14.5a1.25 1.25 0 01-1.25-1.25V3.25zm0 11.25a1.25 1.25 0 011.25-1.25h6.25A1.25 1.25 0 0122 14.5v6.25A1.25 1.25 0 0120.75 22H14.5a1.25 1.25 0 01-1.25-1.25V14.5z"></path>
    </svg>
  );
}

export default CategoryIcon;
