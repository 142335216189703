import React from "react";

function SettingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M12 8.25A3.75 3.75 0 1015.75 12 3.761 3.761 0 0012 8.25zM20.094 12c-.002.35-.027.7-.076 1.047l2.282 1.787a.543.543 0 01.123.693l-2.159 3.727a.546.546 0 01-.663.231l-2.683-1.078a8.27 8.27 0 01-1.82 1.063l-.401 2.85a.56.56 0 01-.54.461H9.84a.562.562 0 01-.54-.447l-.4-2.849a7.94 7.94 0 01-1.82-1.063L4.396 19.5a.545.545 0 01-.663-.23l-2.159-3.728a.543.543 0 01.123-.692l2.283-1.787A8.182 8.182 0 013.903 12c.002-.35.027-.7.077-1.047L1.697 9.166a.543.543 0 01-.123-.693l2.16-3.727a.546.546 0 01.662-.231L7.08 5.593A8.276 8.276 0 018.9 4.53l.4-2.85a.56.56 0 01.54-.461h4.318a.563.563 0 01.54.446l.4 2.85c.653.271 1.266.63 1.823 1.063L19.602 4.5a.546.546 0 01.663.23l2.16 3.728a.543.543 0 01-.124.693l-2.282 1.787c.048.352.074.707.076 1.062z"></path>
    </svg>
  );
}

export default SettingIcon;
