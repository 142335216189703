import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { useGetIntegrationsListQuery } from "../../store/services/integrationService";
import DeleteIntegration from "./DeleteIntegration";

const Integration = () => {
  const [open, setOpen] = useState(false);
  const [deleteIntegrationId, setDeleteIntegrationId] = useState("");
  const { data, isLoading, isFetching } = useGetIntegrationsListQuery();

  const renderColumns = () => {
    return [
      {
        name: "Nome",
        sortable: true,
        selector: (row) => row?.name,
      },
      {
        name: "Status",
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.status).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row.status).toUpperCase()}
              </span>
            )}
            {String(row.status).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.status).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },
      {
        name: "Comentário",
        sortable: true,
        grow: 2,
        selector: (row) => row?.comment,
      },
      {
        name: "Contato",
        selector: (row) => row?.number,
      },
      {
        name: "Ação",
        button: true,
        cell: (row) => (
          <>
            <Link
              to={`/dashboard/integration-update/${row.id}`}
              className="icon cursor-pointer"
            >
              <EditIcon />
            </Link>
            <div
              className="icon cursor-pointer"
              onClick={(e) => {
                setOpen(true);
                setDeleteIntegrationId({ id: row.id, name: row.name });
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ),
      },
    ];
  };
  return (
    <div className="integration-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-title">Integração</div>

        <div className="table-blk flex flex-col">
          <div className="action flex items-center justify-end">
            <Link to={"/dashboard/new-integration"} className="btn-new button">
              Add Nova Integração
            </Link>
          </div>
          <div className="tbl flex flex-col">
            <>
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true}
              />
            </>
            {isLoading || isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DeleteIntegration setOpen={setOpen} data={deleteIntegrationId} />
      </Modal>
    </div>
  );
};

export default Integration;
