import React from "react";

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M6 19.2s-1.2 0-1.2-1.2S6 13.2 12 13.2s7.2 3.6 7.2 4.8c0 1.2-1.2 1.2-1.2 1.2H6zm6-7.2a3.6 3.6 0 100-7.2 3.6 3.6 0 000 7.2z"></path>
    </svg>
  );
}

export default UserIcon;
