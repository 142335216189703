import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Private from "./Private";
import Public from "./Public";

import Login from "../Pages/Login";
// import Registration from "../Pages/Registration";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import Home from "../Pages/Home";
import Categories from "../Pages/Categories";
import NewCategory from "../Pages/Categories/NewCategory";
import UpdateCategory from "../Pages/Categories/UpdateCategory";
import Integration from "../Pages/Integration";
import NewIntegration from "../Pages/Integration/NewIntegration";
import UpdateIntegration from "../Pages/Integration/UpdateIntegration";
import Setting from "../Pages/Setting";
import ModulesPage from "../Pages/Modules";
import NewModule from "../Pages/Modules/NewModule";
import UpdateModule from "../Pages/Modules/UpdateModule";
import CronPage from "../Pages/Cron";
import NewCron from "../Pages/Cron/NewCron";
import UpdateCron from "../Pages/Cron/UpdateCron";
import EmployeesPage from "../Pages/Employee";
import NewEmployee from "../Pages/Employee/NewEmployee";
import UpdateEmployee from "../Pages/Employee/UpdateEmployee";
import CompanyPage from "../Pages/Company";
import NewCompany from "../Pages/Company/NewCompany";
import UpdateCompany from "../Pages/Company/UpdateCompany";
import AssociatesPage from "../Pages/Associates";
import NewAssociate from "../Pages/Associates/NewAssociates";
import UpdateAssociate from "../Pages/Associates/UpdateAssociates";
import FinancialPage from "../Pages/Financial";
import AddPlan from "../Pages/Plan/AddPlan";
import CompanyPlanList from "../Pages/Plan/CompanyPlanList";
import UpdatePlan from "../Pages/Plan/UpdatePlan";

const Routing = () => {
  const { openSidebar } = useSelector((state) => state.globalReducer);
  const { adminToken } = useSelector((state) => state.authReducer);

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          {/* <Route
            path="register"
            element={
              <Public>
                <Registration />
              </Public>
            }
          /> */}
        </Route>
      </Routes>
      {adminToken && (
        <div
          className={`dashboard-page flex flex-col relative ${
            openSidebar ? "full-open" : ""
          }`}
        >
          <Sidebar />
          <div className="pages-block flex flex-col relative h-full">
            <Header />
            <Routes>
              <Route path="dashboard">
                <Route
                  path="home"
                  element={
                    <Private>
                      <Home />
                    </Private>
                  }
                />
                <Route
                  path="categories"
                  element={
                    <Private>
                      <Categories />
                    </Private>
                  }
                />
                <Route
                  path="new-categories"
                  element={
                    <Private>
                      <NewCategory />
                    </Private>
                  }
                />
                <Route
                  path="category-update/:uId"
                  element={
                    <Private>
                      <UpdateCategory />
                    </Private>
                  }
                />

                <Route
                  path="setting"
                  element={
                    <Private>
                      <Setting />
                    </Private>
                  }
                />

                <Route
                  path="integrations"
                  element={
                    <Private>
                      <Integration />
                    </Private>
                  }
                />
                <Route
                  path="new-integration"
                  element={
                    <Private>
                      <NewIntegration />
                    </Private>
                  }
                />
                <Route
                  path="integration-update/:id"
                  element={
                    <Private>
                      <UpdateIntegration />
                    </Private>
                  }
                />

                <Route
                  path="modules"
                  element={
                    <Private>
                      <ModulesPage />
                    </Private>
                  }
                />

                <Route
                  path="new-module"
                  element={
                    <Private>
                      <NewModule />
                    </Private>
                  }
                />

                <Route
                  path="module-update/:id"
                  element={
                    <Private>
                      <UpdateModule />
                    </Private>
                  }
                />

                <Route
                  path="cron"
                  element={
                    <Private>
                      <CronPage />
                    </Private>
                  }
                />

                <Route
                  path="new-cron"
                  element={
                    <Private>
                      <NewCron />
                    </Private>
                  }
                />

                <Route
                  path="cron-update/:id"
                  element={
                    <Private>
                      <UpdateCron />
                    </Private>
                  }
                />

                <Route
                  path="employee"
                  element={
                    <Private>
                      <EmployeesPage />
                    </Private>
                  }
                />

                <Route
                  path="new-employee"
                  element={
                    <Private>
                      <NewEmployee />
                    </Private>
                  }
                />
                <Route
                  path="employee-update/:id"
                  element={
                    <Private>
                      <UpdateEmployee />
                    </Private>
                  }
                />

                <Route
                  path="companies"
                  element={
                    <Private>
                      <CompanyPage />
                    </Private>
                  }
                />

                <Route
                  path="new-company"
                  element={
                    <Private>
                      <NewCompany />
                    </Private>
                  }
                />

                <Route
                  path="company-update/:id"
                  element={
                    <Private>
                      <UpdateCompany />
                    </Private>
                  }
                />

                <Route
                  path="associates"
                  element={
                    <Private>
                      <AssociatesPage />
                    </Private>
                  }
                />

                <Route
                  path="new-associate"
                  element={
                    <Private>
                      <NewAssociate />
                    </Private>
                  }
                />

                <Route
                  path="associate-update/:id"
                  element={
                    <Private>
                      <UpdateAssociate />
                    </Private>
                  }
                />

                <Route
                  path="financial"
                  element={
                    <Private>
                      <FinancialPage />
                    </Private>
                  }
                />
                <Route
                  path="add-plan"
                  element={
                    <Private>
                      <AddPlan />
                    </Private>
                  }
                />
                <Route
                  path="companyPlanList/:id"
                  element={
                    <Private>
                      <CompanyPlanList />
                    </Private>
                  }
                />
                <Route
                  path="update-plan/:id"
                  element={
                    <Private>
                      <UpdatePlan />
                    </Private>
                  }
                />
              </Route>
            </Routes>
          </div>
        </div>
      )}
    </BrowserRouter>
  );
};

export default Routing;
