import { createSlice } from "@reduxjs/toolkit";
const customerToken = localStorage.getItem("admin-token");

const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    adminToken: localStorage.getItem("admin-token"),
  },
  reducers: {
    setAdminToken: (state, action) => {
      state.adminToken = action.payload;
    },

    logout: (state, { payload }) => {
      localStorage.removeItem(payload);
      localStorage.removeItem("sidebar");
      state.adminToken = null;
    },
  },
});
export const { setAdminToken, logout } = authReducer.actions;
export default authReducer.reducer;
