import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const associateService = createApi({
  reducerPath: "associateService",
  tagTypes: "associat",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Associate
      createNewAssociate: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-associat",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["associat"],
      }),

      //   Update the Associate
      updateAssociate: builder.mutation({
        query: ({ formData, id }) => {
          return {
            url: `/api/update-associat/${id}`,
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["associat"],
      }),

      //   Delete a Associate
      deleteAssociate: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete-associat/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["associat"],
      }),

      //   Get the List of Associate
      getAssociates: builder.query({
        query: () => {
          return {
            url: "/api/view-associat",
            method: "GET",
          };
        },
        providesTags: ["associat"],
      }),

      //   Get the Single Associate Data
      fetchAssociate: builder.query({
        query: (id) => {
          return {
            url: `/api/view-associat/${id}`,
            method: "GET",
          };
        },
        providesTags: ["associat"],
      }),
    };
  },
});

export const {
  useGetAssociatesQuery,
  useCreateNewAssociateMutation,
  useDeleteAssociateMutation,
  useFetchAssociateQuery,
  useUpdateAssociateMutation,
} = associateService;

export default associateService;
