import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { CameraIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import {
  useFetchCategoryQuery,
  useUpdateCategoryMutation,
} from "../../store/services/categoryService";

const UpdateCategory = () => {
  // focus(".txtBox");
  const navigate = useNavigate();
  const { uId } = useParams();
  const { data, isLoading, isFetching } = useFetchCategoryQuery(uId);

  const [img, setImg] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const statusData = [
    { display: "Active", value: "active" },
    { display: "Inactive", value: "inactive" },
  ];
  const [createCategory, setCreateCategory] = useState({
    name: "",
    description: "",
    status: "",
    image: "",
  });
  const handleInput = (e) => {
    setCreateCategory({ ...createCategory, [e.target.name]: e.target.value });
  };

  const handleStatus = (e) => {
    setCreateCategory({ ...createCategory, status: e.value });
    setSelectedStatus({ display: e.display, value: e.value });
  };

  useEffect(() => {
    setCreateCategory({
      ...createCategory,
      image: img,
    });
  }, [img]);

  useEffect(() => {
    if (!isFetching) {
      setCreateCategory(data?.data);
      setSelectedStatus({ display: data.data.status, value: data.data.status });
    }
  }, [data?.data]);

  const [updateCategory, response] = useUpdateCategoryMutation();
  const errors = response?.error?.data ? response?.error?.data.message : null;

  const updateCreateCategory = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", createCategory.id);
    formData.append("name", createCategory.name);
    formData.append("image", createCategory.image);
    formData.append("description", createCategory.description);
    formData.append("status", createCategory.status);
    formData.append("_method", "PATCH");
    updateCategory({ formData, uId });
  };

  useEffect(() => {
    if (response?.isSuccess) {
      navigate("/dashboard/categories");
    }
  }, [response?.isSuccess]);

  return (
    <form onSubmit={updateCreateCategory} className="new-category flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Atualizar Categoria</div>
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <div className="row">
                <div className="field flex flex-col">
                  <div className="lbl">Nome *</div>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="txt"
                    placeholder="Nome"
                    value={createCategory.name}
                    onChange={handleInput}
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Status</div>
                  <Select
                    defaultValue={selectedStatus}
                    onChange={handleStatus}
                    getOptionLabel={(option) => option.display}
                    getOptionValue={(option) => option.value}
                    options={statusData}
                    className="select"
                    id="status"
                    name="status"
                    placeholder="Selecionar Status"
                  />
                </div>
              </div>
              <div className="row pt-6">
                <div className="field flex flex-col ">
                  <div className="lbl">Descrição</div>
                  <textarea
                    id="description"
                    name="description"
                    type="text"
                    className="txt h-[160px]"
                    placeholder="Descrição"
                    value={createCategory.description}
                    onChange={handleInput}
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Imagem da Categoria</div>
                  <div
                    className="img-box flex flex-col items-center justify-center"
                    onClick={() =>
                      document.getElementById("upload_img").click()
                    }
                  >
                    {img ? (
                      <img src={URL.createObjectURL(img)} className="img" />
                    ) : (
                      <>
                        <img
                          src={`${process.env.REACT_APP_API_URL}${createCategory.image}`}
                          className="img"
                        />
                      </>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      title=""
                      name="image"
                      id="upload_img"
                      className="select-file cleanbtn"
                      onChange={(e) => {
                        setImg(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="action flex aic justify-end">
                <input
                  type="submit"
                  value={response.isLoading ? "Loading..." : "Atualizar"}
                  className="btn button"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default UpdateCategory;
