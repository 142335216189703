import React from "react";

function IntegrationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M6.375 2A.625.625 0 017 2.625v.625h10v-.625a.625.625 0 111.25 0v.625h1.25a2.5 2.5 0 012.5 2.5V19.5a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 012 19.5V5.75a2.5 2.5 0 012.5-2.5h1.25v-.625A.625.625 0 016.375 2zm12.442 3.75H5.181c-.375 0-.681.28-.681.625v1.25c0 .345.305.625.681.625H18.82c.375 0 .681-.28.681-.625v-1.25c0-.345-.305-.625-.683-.625zm-4.317 5a1.25 1.25 0 000 2.5h6.25v-2.5H14.5zm-5 5a1.25 1.25 0 00-1.25-1.25h-5V17h5a1.25 1.25 0 001.25-1.25z"></path>
    </svg>
  );
}

export default IntegrationIcon;
