import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const planService = createApi({
  reducerPath: "planService",
  tagTypes: "plan",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Plan
      createNewPlan: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-plan",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["plan"],
      }),

      //   Update the plan
      updatePlan: builder.mutation({
        query: ({ formData, id }) => {
          return {
            url: `/api/update-plan/${id}`,
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["plan"],
      }),
      //   Delete a plan
      deletePlan: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete-plan/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["plan"],
      }),

      //   Get the List of plan
      getPlans: builder.query({
        query: () => {
          return {
            url: "/api/view-plan",
            method: "GET",
          };
        },
        providesTags: ["plan"],
      }),

      //   Get the Single plan Data
      fetchPlan: builder.query({
        query: (id) => {
          return {
            url: `/api/view-plan/${id}`,
            method: "GET",
          };
        },
        providesTags: ["plan"],
      }),
    };
  },
});

export const {
  useGetPlansQuery,
  useCreateNewPlanMutation,
  useDeletePlanMutation,
  useFetchPlanQuery,
  useUpdatePlanMutation,
} = planService;

export default planService;
