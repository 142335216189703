import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const accessService = createApi({
  reducerPath: "accessService",
  tagTypes: "access",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New access
      createNewAccess: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-access",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["access"],
      }),

      //   Update the access
      updateAccess: builder.mutation({
        query: ({ formData, id }) => {
          return {
            url: `/api/update-access/${id}`,
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["access"],
      }),

      //   Delete a access
      deleteAccess: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete-access/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["access"],
      }),

      //   Get the List of access
      getAccessList: builder.query({
        query: () => {
          return {
            url: "/api/view-access",
            method: "GET",
          };
        },
        providesTags: ["access"],
      }),

      //   Get the Single access Data
      fetchAccess: builder.query({
        query: (id) => {
          return {
            url: `/api/view-access/${id}`,
            method: "GET",
          };
        },
        providesTags: ["access"],
      }),
    };
  },
});

export const {
  useGetAccessListQuery,
  useCreateNewAccessMutation,
  useDeleteAccessMutation,
  useFetchAccessQuery,
  useUpdateAccessMutation,
} = accessService;

export default accessService;
