import React from "react";

function ReportIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeWidth="2"
        d="M6 18.5V9.621c0-.398.158-.779.44-1.06l4.12-4.122A1.5 1.5 0 0111.622 4H16.5A1.5 1.5 0 0118 5.5v13a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 016 18.5z"
      ></path>
      <path
        stroke="#000"
        strokeWidth="1.5"
        d="M6 10h4.5A1.5 1.5 0 0012 8.5V4"
      ></path>
    </svg>
  );
}

export default ReportIcon;
