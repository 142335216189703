import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { useGetModulesQuery } from "../../store/services/moduleService";
import DeleteModule from "./DeleteModule";

const ModulesPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModuleId, setDeleteModuleId] = useState("");
  const { data, isLoading, isFetching } = useGetModulesQuery();

  const renderColumns = () => {
    return [
      {
        name: "Logomarca",
        cell: (row) => (
          <>
            <img
              src={`${process.env.REACT_APP_API_URL}${row.image}`}
              className="c-img"
            />
          </>
        ),
      },
      {
        name: "Nome",
        sortable: true,
        selector: (row) => row?.name,
      },
      {
        name: "Status",
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.statuses).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row.statuses).toUpperCase()}
              </span>
            )}
            {String(row.statuses).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.statuses).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },
      {
        name: "Categoria",
        sortable: true,
        selector: (row) => row?.categories_id,
      },
      {
        name: "Integração",
        sortable: true,
        selector: (row) => row?.integrations_id,
      },
      {
        name: "Suporte Técnico",
        sortable: true,
        selector: (row) => row?.contact,
      },
      {
        name: "Vencimento",
        sortable: true,
        selector: (row) => row?.billing,
      },
      {
        name: "Valor Unitário",
        sortable: true,
        selector: (row) => row?.unitary_value,
      },
      {
        name: "Valor Pago",
        sortable: true,
        selector: (row) => row?.amount_paid,
      },
      // {
      //   name: "Descrição",
      //   sortable: true,
      //   grow: 2,
      //   selector: (row) => row?.description,
      // },
      {
        name: "Ação",
        button: true,
        cell: (row) => (
          <>
            <Link
              to={`/dashboard/module-update/${row.id}`}
              className="icon cursor-pointer"
            >
              <EditIcon />
            </Link>
            <div
              className="icon cursor-pointer"
              onClick={(e) => {
                setOpen(true);
                setDeleteModuleId({ id: row.id, name: row.name });
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ),
      },
    ];
  };

  return (
    <div className="modules-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-title">Lista de Módulos</div>
        <div className="table-blk flex flex-col">
          <div className="action flex items-center justify-end">
            <Link to={"/dashboard/new-module"} className="btn-new button">
              Add Novo Módulo
            </Link>
          </div>
          <div className="tbl flex flex-col">
            <>
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true}
              />
            </>
            {isLoading || isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DeleteModule setOpen={setOpen} data={deleteModuleId} />
      </Modal>
    </div>
  );
};

export default ModulesPage;
