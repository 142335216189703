import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CompanyIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import { useGetGeneralDataQuery } from "../../store/services/settingService";

const Main = () => {
  const {
    data: generalData,
    isLoading: generalDataLoading,
    isFetching: generalDataFetching,
  } = useGetGeneralDataQuery();

  // console.log("What is home res", generalData);

  const reportList = [
    {
      tag: "Total Companies",
      icon: <CompanyIcon />,
      number: "21,234",
      slug: "/",
    },
    {
      tag: "Total Associates",
      icon: <CompanyIcon />,
      number: "22,345",
      slug: "/",
    },
    { tag: "Total Modules", icon: <CompanyIcon />, number: "16", slug: "/" },
    { tag: "Total Plans", icon: <CompanyIcon />, number: "16", slug: "/" },
    {
      tag: "Receivable",
      icon: <CompanyIcon />,
      number: "R$ 2.000,00",
      slug: "/",
    },
    { tag: "Total Categories", icon: <CompanyIcon />, number: "9", slug: "/" },
    {
      tag: "Total Integration",
      icon: <CompanyIcon />,
      number: "31",
      slug: "/",
    },
    { tag: "Total Cron", icon: <CompanyIcon />, number: "110", slug: "/" },
  ];

  return (
    <div className="lading-page bg-white h-screen flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="home-report">
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                {generalData?.totalcompany ? generalData?.totalcompany : "0"}
              </div>
              <div className="lbl">Total Empresas</div>
            </div>
          </div>
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                {generalData?.totalassociat ? generalData?.totalassociat : "0"}
              </div>
              <div className="lbl">Total Associados</div>
            </div>
          </div>
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                {generalData?.totalmodule ? generalData?.totalmodule : "0"}
              </div>
              <div className="lbl">Total Módulos</div>
            </div>
          </div>
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                {generalData?.totalplan ? generalData?.totalplan : "0"}
              </div>
              <div className="lbl">Total Planos</div>
            </div>
          </div>
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                R${" "}
                {generalData?.totalrecivable
                  ? generalData?.totalrecivable
                  : "0"}
              </div>
              <div className="lbl">Receita</div>
            </div>
          </div>
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                {generalData?.totalcategory ? generalData?.totalcategory : "0"}
              </div>
              <div className="lbl">Total Categorias</div>
            </div>
          </div>
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                {generalData?.totalintegration
                  ? generalData?.totalintegration
                  : "0"}
              </div>
              <div className="lbl">Total Integrações</div>
            </div>
          </div>
          <div className="report-item flex aic cursor-pointer">
            <div className="icon flex items-center justify-center">
              {<CompanyIcon />}
            </div>
            <div className="meta flex flex-col">
              <div className="val">
                {generalData?.totalcron ? generalData?.totalcron : "0"}
              </div>
              <div className="lbl">Total Crons</div>
            </div>
          </div>
        </div>
        {generalDataLoading && (
          <>
            <Loader />
          </>
        )}
      </div>
    </div>
  );
};

export default Main;
