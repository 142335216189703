import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/reducers/authReducer";
import { BellIcon, UserIcon } from "../../assets/icons";
import {
  useFetchSettingDataQuery,
  useGetUserProfileDataQuery,
} from "../../store/services/settingService";

const Header = ({ openSidebar, setOpenSidebar }) => {
  const dispatch = useDispatch();
  const {
    data: settingData,
    isLoading: settingDataLoading,
    isFetching: settingDataFetching,
  } = useFetchSettingDataQuery();
  const {
    data: userProfileData,
    isLoading: userProfileDataLoading,
    isFetching: userProfileDataFetching,
  } = useGetUserProfileDataQuery();

  const [faviconUrl, setFaviconUrl] = useState("");
  const [settingValues, setSettingValues] = useState();

  useEffect(() => {
    if (!settingDataFetching) {
      setSettingValues(settingData?.data);
      setFaviconUrl(settingData?.data.icon);
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = `${process.env.REACT_APP_API_URL}${settingData?.icon}`;
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }, [settingDataFetching]);

  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = `${process.env.REACT_APP_API_URL}${faviconUrl}`;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, [faviconUrl]);

  return (
    <div className="header-camp flex">
      <div className="wrapWidth wrap flex items-center">
        <div className="left flex items-center">
          <div className="logo-img flex items-center justify-center">
            <Link to="/">
              {settingDataFetching ? (
                <div className="b6 text-lg">@ Company Logo</div>
              ) : (
                <img
                  src={`${process.env.REACT_APP_API_URL}${settingValues?.logo}`}
                  className="logo"
                />
              )}
            </Link>
          </div>
        </div>
        <div className="right flex items-center justify-end">
          <div className="notification-box flex items-center justify-center">
            <div className="notification-icon flex items-center justify-center cursor-pointer relative">
              <BellIcon />
              <div className="notification-number flex items-center justify-center z-10">
                2
              </div>
            </div>
          </div>
          {!userProfileDataLoading && (
            <div className="user-info flex items-center justify-center">
              <div className="user-name-meta flex flex-col">
                <div className="name">
                  {userProfileData?.data ? userProfileData?.data.name : ""}
                </div>
                <div className="designation">
                  {userProfileData?.data ? userProfileData?.data.role : ""}
                </div>
              </div>
              <div className="profile-img flex items-center justify-center">
                {userProfileDataLoading ? (
                  <UserIcon />
                ) : (
                  <img
                    src={`${process.env.REACT_APP_API_URL}${userProfileData?.data?.image}`}
                    className="logo"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
