import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { DropDownIcon } from "../../assets/icons";
import { useCreateNewAssociateMutation } from "../../store/services/associateService";
import { useGetPlansQuery } from "../../store/services/planService";
import {
  useGetCompaniesQuery,
  useGetCompaniesNamesQuery,
  useGetCompaniesPlansQuery,
  useGetSingeCompanyPlansQuery,
} from "../../store/services/companyService";

const NewAssociate = () => {
  const navigate = useNavigate();
  const {
    data: companiesList,
    isLoading: companiesLoading,
    isFetching: companiesFetching,
  } = useGetCompaniesNamesQuery();

  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];
  const [selectedPlan, setSelectedPlan] = useState();
  const [createAssociate, setCreateAssociate] = useState({
    name: "",
    company: "",
    plan: "",
    email: "",
    status: "",
    cpf: "",
  });

  const {
    data: currentCompanyPlansList,
    isLoading: currentCompanyPlansLoading,
    isFetching: currentCompanyPlansFetching,
  } = useGetSingeCompanyPlansQuery(createAssociate.company);

  const handleInput = (e) => {
    setCreateAssociate({ ...createAssociate, [e.target.name]: e.target.value });
  };

  const handleStatus = (e) => {
    setCreateAssociate({ ...createAssociate, status: e.value });
  };
  const [hide, setHide] = useState(false);

  const handleCompany = (e) => {
    setCreateAssociate({ ...createAssociate, company: e.id });
    setSelectedPlan("");
  };

  const [saveAssociate, data] = useCreateNewAssociateMutation();

  const submitCreateAssociate = (e) => {
    e.preventDefault();
    saveAssociate(createAssociate);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/dashboard/associates");
      toast.success("Associado Criado!");
    }
  }, [data?.isSuccess]);

  // useEffect(() => {
  //   setSelectedCompany(
  //     plansList?.data?.filter(function (filterItem) {
  //       return filterItem.id === createAssociate.company;
  //     })
  //   );
  // }, [createAssociate.company]);

  useEffect(() => {
    document.addEventListener("click", () => {
      setHide(false);
    });
  }, []);

  return (
    <form onSubmit={submitCreateAssociate} className="new-module flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Criar Novo Associado</div>

          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome *</div>
                <input
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createAssociate.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                <Select
                  // defaultValue={selectedStatus}
                  onChange={handleStatus}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={statusData}
                  className="select"
                  id="status"
                  name="status"
                  placeholder="Selecionar Status"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Empresa</div>
                {!companiesFetching && (
                  <Select
                    onChange={handleCompany}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    options={companiesList?.data}
                    className="select"
                    id="company"
                    name="company"
                    placeholder="Selecionar Empresa"
                  />
                )}
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Plano</div>
                <div className="dropDown flex aic jc flex-col rel flex-[0.3]">
                  <div className="category flex aic">
                    <div
                      className="cbox cleanbtn flex aic rel pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setHide(!hide);
                      }}
                    >
                      <div className="slt flex aic">
                        <div className="unit-name flex aic font s14 b4">
                          <span
                            className="unit-eng flex aic font s14 b4"
                            placeholder="Plano"
                          >
                            {selectedPlan
                              ? selectedPlan
                              : "selecione a empresa"}
                          </span>
                        </div>
                      </div>

                      <div>
                        <DropDownIcon />
                      </div>
                    </div>
                  </div>
                  <div className={`block flex aic abs ${hide ? "show" : ""}`}>
                    <div className="manue flex aic col anim">
                      {currentCompanyPlansList?.data.map((item, index) => (
                        <div
                          key={index}
                          className="slt flex aic"
                          onClick={(e) => {
                            setHide(!hide);
                            setCreateAssociate({
                              ...createAssociate,
                              plan: item.id,
                            });
                            setSelectedPlan(item.name);
                          }}
                        >
                          <div className="unit-name flex aic font s14 b4">
                            <span className="unit-eng flex aic font s14 b4">
                              {item.name}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">CPF *</div>
                <input
                  name="cpf"
                  type="text"
                  className="txt"
                  placeholder="CPF"
                  value={createAssociate.cpf}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Email *</div>
                <input
                  name="email"
                  type="email"
                  className="txt"
                  placeholder="Email"
                  value={createAssociate.email}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Criar"}
                className="btn button"
              />
            </div>
          </>
          {companiesLoading || currentCompanyPlansLoading || data.isLoading ? (
            <Loader />
          ) : (
            <></>
          )}
        </div>
      </div>
    </form>
  );
};

export default NewAssociate;
