import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const financeService = createApi({
  reducerPath: "financeService",
  tagTypes: "finance",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Get Finance  list
      getFinanceList: builder.query({
        query: () => {
          return {
            url: "/api/view-invoice",
            method: "GET",
          };
        },
        providesTags: ["category"],
      }),
    };
  },
});

export const { useGetFinanceListQuery } = financeService;

export default financeService;
