import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CloseIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import Toggle from "../../components/Toggle";
import { toast } from "react-toastify";

import {
  useGetCompaniesQuery,
  useFetchCompanyPlanQuery,
  useUpdateCompanyPlanMutation,
} from "../../store/services/companyService";
import { useGetModulesQuery } from "../../store/services/moduleService";

const UpdatePlan = ({ setOpenUpdate, planId, setRefreshPage }) => {
  const {
    data: planData,
    isLoading: planDataLoading,
    isFetching: planDataFetching,
  } = useFetchCompanyPlanQuery(planId);

  console.log("planData", planData);

  const {
    data: companiesList,
    isLoading: companiesLoading,
    isFetching: companiesFetching,
  } = useGetCompaniesQuery();
  const {
    data: modulesList,
    isLoading: modulesLoading,
    isFetching: modulesFetching,
  } = useGetModulesQuery();

  const navigate = useNavigate();
  const [selection, setSelection] = useState("Plano");

  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];
  const [selectedStatus, setSelectedStatus] = useState({
    display: "",
    value: "",
  });
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [createCompany, setCreateCompany] = useState({
    company_id: "",
    module_unitary_value: "",
    pacakage_type: "Plano",
    options: "1",
    name: "",
    status: "",
    pacakages: [],
    modules: [],
  });

  const handleInput = (e) => {
    setCreateCompany({ ...createCompany, [e.target.name]: e.target.value });
  };

  const handleCompany = (e) => {
    setCreateCompany({ ...createCompany, company_id: e.id });
  };

  const handleChangeOptions = (e) => {
    setCreateCompany({ ...createCompany, options: e.target.value });
  };

  const handlePlanStatus = (e) => {
    setCreateCompany({
      ...createCompany,
      status: e.value,
    });
    // setCreateCompany({
    //   ...createCompany,
    //   plans: {
    //     ...createCompany.plans,
    //     status: e.value,
    //   },
    // });
  };

  const [saveCompany, data] = useUpdateCompanyPlanMutation();

  const submitCreateCompany = (e) => {
    e.preventDefault();
    saveCompany(createCompany);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      toast.success("Company Plan Updated Successfully!");
      setOpenUpdate(false);
      setRefreshPage("1");
    }
  }, [data?.isSuccess]);

  useEffect(() => {
    if (data?.isError) {
      const errorMessages = Object.values(data?.error?.data?.message).reduce(
        (acc, val) => acc.concat(val),
        []
      );

      errorMessages.forEach((error) => {
        toast.error(error);
      });
    }
  }, [data?.isError]);

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.target.value;
      if (!value.trim()) return;
      setSelectedPackage([...selectedPackage, { data: value }]);
      e.target.value = "";
    }
  }

  function removeTag(index) {
    setSelectedPackage(selectedPackage.filter((el, i) => i !== index));
  }
  useEffect(() => {
    setCreateCompany({ ...createCompany, pacakages: selectedPackage });
  }, [selectedPackage]);

  const [totalUnitaryValue, setTotalUnitaryValue] = useState([]);
  console.log("totalUnitaryValue", totalUnitaryValue);
  // Handle Integration Access
  const selectedModulesList = createCompany.modules;
  const UnitaryValueSum = totalUnitaryValue.reduce(
    (acc, obj) => acc + obj.unitary_value,
    0
  );
  useEffect(() => {
    setCreateCompany({
      ...createCompany,
      module_unitary_value: UnitaryValueSum,
    });
  }, [UnitaryValueSum]);

  const [helpModules, setHelpModules] = useState(false);

  useEffect(() => {
    setHelpModules(createCompany.modules);
  }, [createCompany]);

  const handleModules = (e, item) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      let finalSet = [];
      let duplicate = false;

      helpModules.forEach((element) => {
        finalSet.push(element);
        if (item.mId === element.mId) {
          duplicate = true;
        }
      });

      if (duplicate === false) {
        finalSet.push({
          mId: item.id,
          mName: item.name,
        });
      }

      setCreateCompany({
        ...createCompany,
        modules: finalSet,
      });

      // selectedModulesList.push({
      //   mId: item.id,
      //   mName: item.name,
      // });

      // totalUnitaryValue.push({
      //   mId: item.id,
      //   unitary_value: item.unitary_value,
      // });
    }
    // Case 2  : The user unchecked the box
    else {
      let newSelectedModulesList = helpModules.filter(function (filterItem) {
        return filterItem.mId !== item.id;
      });
      setCreateCompany({
        ...createCompany,
        modules: newSelectedModulesList,
      });

      // Remove unitary_value
      setTotalUnitaryValue(
        totalUnitaryValue.filter(function (filterItem) {
          return filterItem.mId !== item.id;
        })
      );
    }
  };
  useEffect(() => {
    if (!planDataFetching) {
      setCreateCompany(planData?.data);
      setSelectedStatus(planData?.data.status);
      setSelection(planData?.data.pacakage_type);
      setSelectedPackage(planData?.data?.pacakages);
    }
  }, [planDataFetching]);

  useEffect(() => {
    if (selection !== "Tombamento") {
      setCreateCompany({
        ...createCompany,
        pacakages: planData?.data?.pacakages,
      });
    }
  }, [selection]);

  useEffect(() => {
    setSelectedStatus(createCompany.status);
  }, [createCompany]);

  return (
    <form onSubmit={submitCreateCompany} className="update-company-plan flex">
      <div className="wrap wrapWidth flex flex-col">
        {!planDataLoading ||
        !modulesFetching ||
        !companiesFetching ||
        !data.isLoading ? (
          <div className="form-block flex flex-col">
            <div className="flex items-center justify-between w-full">
              <div className="page-title">Plano de atualização</div>
              <div
                className="close-icon flex items-center justify-center cursor-pointer"
                onClick={(e) => setOpenUpdate(false)}
              >
                <CloseIcon />
              </div>
            </div>
            <>
              {/* Plan Section */}
              <div className="row my-5">
                <div className="field flex flex-col">
                  <div className="lbl">Nome do Plano *</div>
                  <input
                    name="name"
                    type="text"
                    className="txt"
                    placeholder="Nome do plano"
                    value={createCompany.name}
                    onChange={handleInput}
                  />
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Status do Plano</div>
                  <Select
                    defaultValue={selectedStatus}
                    // value={selectedStatus}
                    onChange={handlePlanStatus}
                    getOptionLabel={(option) => option.display}
                    getOptionValue={(option) => option.value}
                    options={statusData}
                    className="select"
                    id="status"
                    name="status"
                    placeholder="Selecione o Status"
                  />
                </div>
              </div>
              <div className="row1">
                <>
                  <div className="lbl">Selecione o Plano</div>
                  <div className="select-item flex aic">
                    <div
                      className="radio-select flex je cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelection("Plano");
                        setCreateCompany({
                          ...createCompany,
                          pacakage_type: "Plano",
                        });
                      }}
                    >
                      <button
                        className={`cleanbtn radio-btn rel ${
                          selection === "Plano" ? "on" : ""
                        }`}
                      />
                      <div className="lbl">Plano</div>
                    </div>
                    <div
                      className="radio-select flex je cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelection("Produto");
                        setCreateCompany({
                          ...createCompany,
                          pacakage_type: "Produto",
                        });
                      }}
                    >
                      <button
                        className={`cleanbtn radio-btn rel ${
                          selection === "Produto" ? "on" : ""
                        }`}
                      />
                      <div className="lbl">Produto</div>
                    </div>
                    <div
                      className="radio-select flex je cursor-pointer"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelection("Tombamento");
                        setCreateCompany({
                          ...createCompany,
                          pacakage_type: "Tombamento",
                          pacakages: [],
                        });
                      }}
                    >
                      <button
                        className={`cleanbtn radio-btn rel ${
                          selection === "Tombamento" ? "on" : ""
                        }`}
                      />
                      <div className="lbl">Tombamento</div>
                    </div>
                  </div>
                  {selection === "Plano" || selection === "Produto" ? (
                    <div className="field flex flex-col">
                      <div className="lbl">Pacotes (insira e aperte Enter)</div>
                      <div className="tags-input-container">
                        {createCompany?.pacakages?.map((tag, index) => (
                          <div className="tag-item" key={index}>
                            <span className="text">{tag?.data}</span>
                            <span
                              className="close"
                              onClick={() => removeTag(index)}
                            >
                              &times;
                            </span>
                          </div>
                        ))}
                        <input
                          onKeyDown={handleKeyDown}
                          type="text"
                          className="tags-input"
                          placeholder={`${
                            selectedPackage?.length == 0
                              ? "Sem pacotes"
                              : "Insira algo"
                          }`}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </div>
              <div className="row1">
                <div className="modules-list flex items-center flex-wrap">
                  <div className="row">
                    {modulesList?.data.map((items, index) => (
                      <div
                        key={index}
                        className="module-item flex items-center"
                      >
                        <input
                          type="checkbox"
                          name="modules"
                          value={`${items.name}`}
                          onChange={(e) => handleModules(e, items)}
                          checked={
                            createCompany?.modules?.filter(
                              (x) => x.mId === items.id
                            ).length > 0
                          }
                          // checked={
                          //   createCompany?.modules?.filter(
                          //     (x) => x.mId === items.id
                          //   ).length > 0
                          //     ? true
                          //     : false
                          // }
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="lbl">
                          {items?.name ? items?.name : ""}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="field flex flex-col justify-between">
                  <div className="lbl">Opção*</div>
                  <div className="flex items-center w-full">
                    <input
                      name="options"
                      type="range"
                      className="txt w-[60%]"
                      placeholder="Nome do Plano"
                      min={0}
                      max={
                        modulesList?.data?.length
                          ? modulesList?.data?.length
                          : "0"
                      }
                      value={createCompany.options}
                      onChange={handleChangeOptions}
                    />
                    <div className="ml-3"> {createCompany?.options}</div>
                  </div>
                </div>
                <div className="field flex flex-col">
                  <div className="lbl">Valor Unitário</div>
                  <input
                    name="module_unitary_value"
                    type="text"
                    className="txt"
                    placeholder="Unitary Value"
                    value={`R$ ${
                      UnitaryValueSum
                        ? UnitaryValueSum
                        : createCompany.module_unitary_value
                    }`}
                    disabled={true}
                    // onChange={handlePlan}
                  />
                </div>
              </div>
              <div className="separator"></div>
              <div className="action flex aic justify-end">
                <input
                  type="submit"
                  value={data.isLoading ? "Loading..." : "atualização"}
                  className="btn button"
                />
              </div>
            </>
            {modulesFetching || companiesFetching || data.isLoading ? (
              <Loader />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </form>
  );
};

export default UpdatePlan;
