import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { CloseIcon } from "../../assets/icons";
import { useDeleteAssociateMutation } from "../../store/services/associateService";

const DeleteCron = ({ setOpen, data }) => {
  const [removeCron, response] = useDeleteAssociateMutation();

  const deleteCron = (id) => {
    removeCron(id);
  };

  useEffect(() => {
    if (response?.data?.success === true) {
      setOpen(false);
      toast.success("Associate Deleted Successfully!");
    }
  }, [response?.data?.success === true]);

  return (
    <div className="delete-popup flex">
      <div className="wrap flex flex-col">
        <div className="hdr flex justify-end">
          <div
            className="icon flex aic jc cursor-pointer"
            onClick={(e) => setOpen(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="blk flex aic  flex-col justify-around">
          <div className="desc">
            Are you sure to delete ‘{data?.name}’ Associate?
          </div>
          <div className="actions flex aic jc">
            <div className="btn button" onClick={(e) => setOpen(false)}>
              Cancel
            </div>
            <div className="btn button" onClick={(e) => deleteCron(data?.id)}>
              Delete
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCron;
