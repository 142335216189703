import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const employeeService = createApi({
  reducerPath: "employeeService",
  tagTypes: "employee",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Upload Employee Image
      uploadEmployeeImage: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-employee/image",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["employee"],
      }),
      //   Create New employee
      createNewEmployee: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-employee",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["employee"],
      }),

      //   Update the employee
      updateEmployee: builder.mutation({
        query: (formData) => {
          return {
            url: `/api/update-employee/${formData?.id}`,
            method: "PATCH",
            body: formData,
          };
        },
        invalidatesTags: ["employee"],
      }),

      //   Delete a employee
      deleteEmployee: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete-employee/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["employee"],
      }),

      //   Get the List of employees
      getEmployees: builder.query({
        query: () => {
          return {
            url: "/api/view-employee",
            method: "GET",
          };
        },
        providesTags: ["employee"],
      }),

      //   Get the Single employee Data
      fetchEmployee: builder.query({
        query: (id) => {
          return {
            url: `/api/view-employee/${id}`,
            method: "GET",
          };
        },
        providesTags: ["employee"],
      }),
    };
  },
});

export const {
  useGetEmployeesQuery,
  useUploadEmployeeImageMutation,
  useCreateNewEmployeeMutation,
  useDeleteEmployeeMutation,
  useFetchEmployeeQuery,
  useUpdateEmployeeMutation,
} = employeeService;

export default employeeService;
