import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M10.68 19.32v-4.206c0-.294.3-.594.6-.594h2.4c.3 0 .6.3.6.6v4.2a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6v-8.4a.602.602 0 00-.175-.425L18.48 8.872V4.92a.6.6 0 00-.6-.6h-1.2a.6.6 0 00-.6.6v1.552l-3.175-3.177a.6.6 0 00-.85 0l-7.2 7.2a.6.6 0 00-.175.425v8.4a.6.6 0 00.6.6h4.8a.6.6 0 00.6-.6z"></path>
    </svg>
  );
}

export default HomeIcon;
