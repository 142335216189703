import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CameraIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

import {
  useCreateNewCompanyMutation,
  useUploadCompanyImageMutation,
} from "../../store/services/companyService";
import { useGetIntegrationsListQuery } from "../../store/services/integrationService";

const NewCompany = () => {
  const {
    data: integrationList,
    isLoading: integrationLoading,
    isFetching: integrationFetching,
  } = useGetIntegrationsListQuery();

  const navigate = useNavigate();

  const [img, setImg] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];
  const expirationList = [
    { display: 1 },
    { display: 2 },
    { display: 3 },
    { display: 4 },
    { display: 5 },
    { display: 6 },
    { display: 7 },
    { display: 8 },
    { display: 9 },
    { display: 10 },
    { display: 11 },
    { display: 12 },
    { display: 13 },
    { display: 14 },
    { display: 15 },
    { display: 16 },
    { display: 17 },
    { display: 18 },
    { display: 19 },
    { display: 20 },
    { display: 21 },
    { display: 22 },
    { display: 23 },
    { display: 24 },
    { display: 25 },
    { display: 26 },
    { display: 27 },
    { display: 28 },
    { display: 29 },
    { display: 30 },
  ];

  const [selectedIntegration, setSelectedIntegration] = useState();
  const [createCompany, setCreateCompany] = useState({
    name: "",
    company_name: "",
    address: "",
    cnjp: "",
    website: "",
    unitary_value: "",
    image: "",
    contract: "",
    comment: "",
    integration_id: "",
    expiration: "",
    status: "",
    companyintegrationaccess: [],
  });

  const handleInput = (e) => {
    setCreateCompany({ ...createCompany, [e.target.name]: e.target.value });
  };

  const handleStatus = (e) => {
    setCreateCompany({ ...createCompany, status: e.value });
  };
  const handleExpiration = (e) => {
    setCreateCompany({ ...createCompany, expiration: e.display });
  };
  const handleIntegration = (e) => {
    setCreateCompany({
      ...createCompany,
      integration_id: e.id,
      companyintegrationaccess: [],
    });
    setSelectedIntegration(e);
  };
  const handleCompanyIntegrationAccessChange = (event) => {
    const { name, value } = event.target;
    const access = createCompany.companyintegrationaccess;
    const index = access.findIndex((a) => a.name === name);
    if (index !== -1) {
      access[index].value = value;
    } else {
      access.push({ name, value });
    }
    setCreateCompany({
      ...createCompany,
      companyintegrationaccess: access,
    });
  };

  const [saveCompany, data] = useCreateNewCompanyMutation();
  const [saveCompanyImage, imageURl] = useUploadCompanyImageMutation();

  const submitCreateCompany = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", img);
    formData.append("contract", selectedFile);
    saveCompanyImage(formData);
  };

  useEffect(() => {
    if (imageURl?.isSuccess) {
      saveCompany({
        ...createCompany,
        image: imageURl?.data?.image,
        contract: imageURl?.data?.contract,
      });
    }
  }, [imageURl?.isSuccess]);

  useEffect(() => {
    if (imageURl?.isError) {
      const errorMessages = Object.values(
        imageURl?.error?.data?.message
      ).reduce((acc, val) => acc.concat(val), []);
      errorMessages.forEach((error) => {
        toast.error(error);
      });
    }
  }, [imageURl?.isError]);

  useEffect(() => {
    if (data?.isSuccess) {
      toast.success("Company Created Successfully!");
      navigate("/dashboard/companies");
    }
  }, [data?.isSuccess]);

  useEffect(() => {
    if (data?.isError) {
      const errorMessages = Object.values(data?.error?.data?.message).reduce(
        (acc, val) => acc.concat(val),
        []
      );
      errorMessages.forEach((error) => {
        toast.error(error);
      });
    }
  }, [data?.isError]);

  return (
    <form onSubmit={submitCreateCompany} className="new-company flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Adicionar Nova Empresa</div>
          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome *</div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createCompany.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">CNPJ *</div>
                <input
                  name="cnjp"
                  type="text"
                  className="txt"
                  placeholder="CNPJ"
                  value={createCompany.cnjp}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Razão Social*</div>
                <input
                  name="company_name"
                  type="text"
                  className="txt"
                  placeholder="Razão Social"
                  value={createCompany.company_name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Website</div>
                <input
                  name="website"
                  type="url"
                  className="txt"
                  placeholder="https://google.com/"
                  value={createCompany.website}
                  onChange={handleInput}
                />
              </div>

              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                <Select
                  // defaultValue={selectedStatus}
                  onChange={handleStatus}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={statusData}
                  className="select"
                  id="status"
                  name="status"
                  placeholder="Selecione o Status"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Vencimento</div>
                <Select
                  onChange={handleExpiration}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.display}
                  options={expirationList}
                  className="select"
                  id="expiration"
                  name="expiration"
                  placeholder="Selecione o Vencimento"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Valor Unitário</div>
                <input
                  name="unitary_value"
                  type="text"
                  className="txt"
                  placeholder="Valor Unitário"
                  value={createCompany.unitary_value}
                  onChange={handleInput}
                />
              </div>

              <div className="field flex flex-col">
                <div className="lbl">Integração</div>
                {!integrationFetching && (
                  <Select
                    onChange={handleIntegration}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={integrationList?.data}
                    className="select"
                    id="integration_id"
                    name="integration_id"
                    placeholder="Selecione a Integração"
                  />
                )}
              </div>
              {selectedIntegration?.integrationaccess?.map((item, index) => (
                <div key={index} className="field flex flex-col">
                  <div className="lbl">{item.name}</div>
                  <input
                    name={item.name}
                    type="text"
                    className="txt"
                    placeholder={item.name}
                    onChange={handleCompanyIntegrationAccessChange}
                  />
                </div>
              ))}

              <div className="field flex flex-col">
                <div className="lbl">Endereço</div>
                <textarea
                  name="address"
                  type="text"
                  className="txt min-h-[100px]"
                  placeholder="Endereço"
                  value={createCompany.address}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Comentários</div>
                <textarea
                  name="comment"
                  type="text"
                  className="txt min-h-[100px]"
                  placeholder="Comentários..."
                  value={createCompany.comment}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="row mt-5">
              <div className="field flex flex-col">
                <div className="lbl">Logomarca</div>
                <div
                  className="img-box flex flex-col items-center justify-center"
                  onClick={() => document.getElementById("upload_img").click()}
                >
                  {img ? (
                    <img src={URL.createObjectURL(img)} className="img" />
                  ) : (
                    <>
                      <CameraIcon />
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    name="image"
                    id="upload_img"
                    className="select-file cleanbtn"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setImg(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Upload Contrato</div>
                <div
                  className="img-box flex flex-col items-center justify-center"
                  onClick={() => document.getElementById("upload_file").click()}
                >
                  {selectedFile ? (
                    <>{selectedFile?.name}</>
                  ) : (
                    <>
                      <CameraIcon />
                    </>
                  )}
                  <input
                    type="file"
                    accept="*"
                    title=""
                    name="image"
                    id="upload_file"
                    className="select-file cleanbtn"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      console.log("file", file);
                      setSelectedFile(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Criar"}
                className="btn button"
              />
            </div>
          </>
          {integrationFetching || data.isLoading ? <Loader /> : <></>}
        </div>
      </div>
    </form>
  );
};

export default NewCompany;
