import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { useGetEmployeesQuery } from "../../store/services/employeeService";
import DeleteEmployee from "./DeleteEmployee";

const EmployeesPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModuleId, setDeleteModuleId] = useState("");
  const { data, isLoading, isFetching } = useGetEmployeesQuery();

  const renderColumns = () => {
    return [
      {
        name: "Imagem",
        cell: (row) => (
          <>
            <img
              src={`${process.env.REACT_APP_API_URL}${row.image}`}
              className="c-img"
            />
          </>
        ),
      },
      {
        name: "Nome",
        sortable: true,
        selector: (row) => row?.name,
      },
      {
        name: "Status",
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.status).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row.status).toUpperCase()}
              </span>
            )}
            {String(row.status).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.status).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },
      {
        name: "Acesso",
        sortable: true,
        selector: (row) => row?.role,
      },
      {
        name: "Cargo",
        sortable: true,
        selector: (row) => row?.office,
      },
      {
        name: "Email",
        sortable: true,
        selector: (row) => row?.email,
      },

      {
        name: "Ação",
        button: true,
        cell: (row) => (
          <>
            <Link
              to={`/dashboard/employee-update/${row.id}`}
              className="icon cursor-pointer"
            >
              <EditIcon />
            </Link>
            <div
              className="icon cursor-pointer"
              onClick={(e) => {
                setOpen(true);
                setDeleteModuleId({ id: row.id, name: row.name });
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ),
      },
    ];
  };

  return (
    <div className="employee-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-title">lista de Funcionários</div>
        <div className="table-blk flex flex-col">
          <div className="action flex items-center justify-end">
            <Link to={"/dashboard/new-employee"} className="btn-new button">
              Add Novo Funcionário
            </Link>
          </div>
          <div className="tbl flex flex-col">
            <>
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true}
              />
            </>
            {isLoading || isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DeleteEmployee setOpen={setOpen} data={deleteModuleId} />
      </Modal>
    </div>
  );
};

export default EmployeesPage;
