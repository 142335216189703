import React from "react";

function BellIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M11.52 21.6a2.4 2.4 0 002.4-2.4h-4.8a2.4 2.4 0 002.4 2.4zm1.194-17.88a1.2 1.2 0 10-2.388 0A6.002 6.002 0 005.52 9.6c0 1.318-.6 7.2-2.4 8.4h16.8c-1.8-1.2-2.4-7.082-2.4-8.4a6.002 6.002 0 00-4.806-5.88z"></path>
    </svg>
  );
}

export default BellIcon;
