import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../components/Loader";
import {
  useUpdateEmployeeMutation,
  useUploadEmployeeImageMutation,
  useFetchEmployeeQuery,
} from "../../store/services/employeeService";
import { useGetAccessListQuery } from "../../store/services/accessService";
import { useGetRolesQuery } from "../../store/services/roleService";

const UpdateEmployee = () => {
  const { id } = useParams();
  const {
    data: accessList,
    isLoading: accessLoading,
    isFetching: accessFetching,
  } = useGetAccessListQuery();

  const {
    data: rolesList,
    isLoading: rolesLoading,
    isFetching: rolesFetching,
  } = useGetRolesQuery();

  const {
    data: employeeData,
    isLoading: employeeLoading,
    isFetching: employeeFetching,
  } = useFetchEmployeeQuery(id);

  const navigate = useNavigate();
  const [img, setImg] = useState();
  const [uploadImg, setUploadImg] = useState();
  const [helpEmployeeAccess, setHelpEmployeeAccess] = useState(false);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState({
    display: "",
    value: "",
  });
  const [selectedStatus, setSelectedStatus] = useState({
    display: "",
    value: "",
  });
  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];

  const [createEmployee, setCreateEmployee] = useState({
    name: "",
    email: "",
    password: "",
    image: "",
    status: "",
    phone: "",
    office: "",
    role: "",
    access: [],
  });

  const handleInput = (e) => {
    setCreateEmployee({ ...createEmployee, [e.target.name]: e.target.value });
  };

  const handleStatus = (e) => {
    setCreateEmployee({ ...createEmployee, status: e.value });
  };

  const handleAccessLevel = (e) => {
    setCreateEmployee({ ...createEmployee, role: e.slug });
  };

  useEffect(() => {
    setHelpEmployeeAccess(createEmployee?.access);
  }, [createEmployee]);

  // Handle Employee Access
  const handleEmployeeAccess = (e, item) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      let finalSet = [];
      let duplicate = false;
      helpEmployeeAccess.forEach((element) => {
        finalSet.push(element);
        if (item.id === element.id) {
          duplicate = true;
        }
      });
      if (duplicate === false) {
        finalSet.push(item);
      }
      setCreateEmployee({
        ...createEmployee,
        access: finalSet,
      });
    }
    // Case 2  : The user unchecked the box
    else {
      let newEmployeeAccessList = helpEmployeeAccess.filter(function (
        filterItem
      ) {
        return filterItem.id !== item.id;
      });
      setCreateEmployee({
        ...createEmployee,
        access: newEmployeeAccessList,
      });
    }
  };

  const [saveEmployee, data] = useUpdateEmployeeMutation();
  const [saveEmployeeImage, imageURl] = useUploadEmployeeImageMutation();

  const submitCreateEmployee = (e) => {
    e.preventDefault();
    if (img) {
      const formData = new FormData();
      formData.append("image", img);
      saveEmployeeImage(formData);
    } else {
      saveEmployee(createEmployee);
    }
  };

  useEffect(() => {
    if (imageURl?.isSuccess) {
      setCreateEmployee({
        ...createEmployee,
        image: imageURl?.data?.data,
      });
      saveEmployee({ ...createEmployee, image: imageURl?.data?.data });
    }
  }, [imageURl?.isSuccess]);

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/dashboard/employee");
    }
  }, [data?.isSuccess]);

  useEffect(() => {
    if (!employeeFetching) {
      setUploadImg(employeeData?.data?.image);
      setCreateEmployee(employeeData?.data);
      setSelectedStatus({
        display: employeeData?.data?.status,
        value: employeeData.data.status,
      });
      setSelectedAccessLevel({
        display: employeeData?.data?.role,
        value: employeeData.data.role,
      });
    }
  }, [employeeFetching]);

  useEffect(() => {
    setSelectedStatus({
      display: createEmployee?.status,
      value: createEmployee?.status,
    });
    setSelectedAccessLevel({
      display: createEmployee?.role,
      value: createEmployee?.role,
    });
  }, [createEmployee]);

  console.log("formData", createEmployee);

  return (
    <form onSubmit={submitCreateEmployee} className="new-employee flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Atualizar Funcionário</div>

          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome *</div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createEmployee.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                {!employeeLoading && (
                  <Select
                    defaultValue={selectedStatus}
                    onChange={handleStatus}
                    getOptionLabel={(option) => option.display}
                    getOptionValue={(option) => option.value}
                    options={statusData}
                    className="select"
                    id="status"
                    name="status"
                    placeholder="Selecionar Status"
                  />
                )}
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Nivel de Acesso</div>
                {!employeeLoading && (
                  <Select
                    defaultValue={selectedAccessLevel}
                    onChange={handleAccessLevel}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={rolesList?.data}
                    className="select"
                    name="role"
                    placeholder="Selecionar Permissão"
                  />
                )}
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Telefone</div>
                <input
                  name="phone"
                  type="number"
                  className="txt"
                  placeholder="Telephone"
                  value={createEmployee.phone}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Cargo</div>
                <input
                  name="office"
                  type="text"
                  className="txt"
                  placeholder="Cargo"
                  value={createEmployee.office}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Email</div>
                <input
                  name="email"
                  type="email"
                  className="txt"
                  placeholder="Email"
                  value={createEmployee.email}
                  onChange={handleInput}
                />
              </div>
              {/* <div className="field flex flex-col">
                <div className="lbl">Password</div>
                <input
                  name="password"
                  type="text"
                  className="txt"
                  placeholder="Password"
                  value={createEmployee.password}
                  onChange={handleInput}
                />
              </div> */}

              <div className="field flex flex-col">
                <div className="lbl">Imagem do Usuário</div>
                <div
                  className="img-box flex flex-col items-center justify-center"
                  onClick={() => document.getElementById("upload_img").click()}
                >
                  {img ? (
                    <img src={URL.createObjectURL(img)} className="img" />
                  ) : (
                    <>
                      <img
                        src={`${process.env.REACT_APP_API_URL}${createEmployee.image}`}
                        className="img"
                      />
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    name="image"
                    id="upload_img"
                    className="select-file cleanbtn"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setImg(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row1">
              {!accessLoading && (
                <div className="field flex w-full">
                  <div className="row5 w-full">
                    {accessList?.data.map((item, index) => (
                      <div className="item flex aic mr-7" key={index}>
                        <input
                          type="checkbox"
                          name="access"
                          value={`${item.name}`}
                          onChange={(e) => handleEmployeeAccess(e, item)}
                          checked={
                            createEmployee?.access?.filter(
                              (x) => x.id === item.id
                            ).length > 0
                          }
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">{item.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Atualizar"}
                className="btn button"
              />
            </div>
          </>
          {rolesFetching || data.isLoading ? <Loader /> : <></>}
        </div>
      </div>
    </form>
  );
};

export default UpdateEmployee;
