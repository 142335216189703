import React from "react";

function ChartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M22.062 12.12h-9.334l-6.6 6.6a9.6 9.6 0 0015.933-6.6h.001zM5.28 17.87a9.6 9.6 0 016.6-15.932v9.334l-6.6 6.6v-.001zm7.8-15.932v8.982h8.982a9.602 9.602 0 00-8.982-8.982z"></path>
    </svg>
  );
}

export default ChartIcon;
