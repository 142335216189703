import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authService = createApi({
  reducerPath: "auth",
  tagTypes: "uData",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => {
    return {
      // API For Logins
      authLogin: builder.mutation({
        query: (loginData) => {
          return {
            url: "api/login",
            method: "POST",
            body: loginData,
          };
        },
      }),
      // userRegister: builder.mutation({
      //   query: (data) => {
      //     return {
      //       url: "api/register",
      //       method: "POST",
      //       body: data,
      //     };
      //   },
      // }),
      // userLogin: builder.mutation({
      //   query: (loginData) => {
      //     return {
      //       url: "api//login",
      //       method: "POST",
      //       body: loginData,
      //     };
      //   },
      // }),
      //      useUserRegisterMutation,
      // useUserLoginMutation,
    };
  },
});
export const { useAuthLoginMutation } = authService;
export default authService;
