import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { useGetAssociatesQuery } from "../../store/services/associateService";
import DeleteAssociates from "./DeleteAssociates";

const AssociatesPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModuleId, setDeleteModuleId] = useState("");
  const { data, isLoading, isFetching } = useGetAssociatesQuery();
  console.log("Associates list", data?.data);

  const renderColumns = () => {
    return [
      {
        name: "Nome",
        sortable: true,
        selector: (row) => row?.name,
      },
      {
        name: "Status",
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.status).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row?.status).toUpperCase()}
              </span>
            )}
            {String(row.status).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.status).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },
      {
        name: "Empresa",
        sortable: true,
        selector: (row) => row?.company?.name,
      },
      {
        name: "CPF",
        sortable: true,
        selector: (row) => row?.cpf,
      },
      {
        name: "Planos",
        sortable: true,
        selector: (row) => row?.plans?.name,
      },

      {
        name: "Email",
        grow: 2,
        sortable: true,
        selector: (row) => row?.email,
      },
      {
        name: "Ação",
        button: true,
        cell: (row) => (
          <>
            <Link
              to={`/dashboard/associate-update/${row.id}`}
              className="icon cursor-pointer"
            >
              <EditIcon />
            </Link>
            <div
              className="icon cursor-pointer ml-3"
              onClick={(e) => {
                setOpen(true);
                setDeleteModuleId({ id: row.id, name: row.name });
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ),
      },
    ];
  };

  return (
    <div className="associates-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-title">Lista de Associados</div>
        <div className="table-blk flex flex-col">
          <div className="action flex items-center justify-end">
            <Link to={"/dashboard/new-associate"} className="btn-new button">
              Add Novo Associado
            </Link>
          </div>
          <div className="tbl flex flex-col">
            <>
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true}
              />
            </>
            {isLoading || isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DeleteAssociates setOpen={setOpen} data={deleteModuleId} />
      </Modal>
    </div>
  );
};

export default AssociatesPage;
