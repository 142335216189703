import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CameraIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import { useCreateNewIntegrationMutation } from "../../store/services/integrationService";
import { useGetIntegrationAccessQuery } from "../../store/services/integrationAccessService";

const NewIntegration = () => {
  // focus(".txtBox");
  const navigate = useNavigate();
  const {
    data: integrationAccessData,
    isLoading: integrationAccessLoading,
    isFetching: integrationAccessFetching,
  } = useGetIntegrationAccessQuery();

  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [createIntegration, setCreateIntegration] = useState({
    name: "",
    comment: "",
    status: "",
    number: "",
    link_api: "",
    website: "",
    integrationaccess: [],
  });
  console.log("form data", createIntegration);

  const handleInput = (e) => {
    setCreateIntegration({
      ...createIntegration,
      [e.target.name]: e.target.value,
    });
  };

  const handleStatus = (e) => {
    setCreateIntegration({ ...createIntegration, status: e.value });
    setSelectedStatus({ display: e.display, value: e.value });
  };

  // Handle Integration Access
  const integrationAccessList = createIntegration.integrationaccess;
  const handleIntegrationAccess = (e, item) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      integrationAccessList.push({
        id: item.id,
        name: item.name,
      });
      setCreateIntegration({
        ...createIntegration,
        integrationaccess: integrationAccessList,
      });
    }
    // Case 2  : The user unchecked the box
    else {
      let newIntegrationAccessList = integrationAccessList.filter(function (
        filterItem
      ) {
        return filterItem.id !== item.id;
      });
      setCreateIntegration({
        ...createIntegration,
        integrationaccess: newIntegrationAccessList,
      });
    }
  };

  const [saveIntegration, data] = useCreateNewIntegrationMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;

  const submitCreateIntegration = (e) => {
    e.preventDefault();
    saveIntegration(createIntegration);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/dashboard/integrations");
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={submitCreateIntegration} className="new-integration flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Criar Nova Integração</div>
          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome *</div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createIntegration.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                <Select
                  defaultValue={selectedStatus}
                  onChange={handleStatus}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={statusData}
                  className="select"
                  id="status"
                  name="status"
                  placeholder="Selecionar Status"
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Website</div>
                <input
                  name="website"
                  type="url"
                  className="txt"
                  placeholder="https://google.com"
                  value={createIntegration.website}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Link da API</div>
                <input
                  name="link_api"
                  type="url"
                  className="txt"
                  placeholder="https://google.com/"
                  value={createIntegration.link_api}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Número de Contato</div>
                <input
                  name="number"
                  type="number"
                  className="txt"
                  placeholder="(31)99999-9999"
                  value={createIntegration.number}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Comentário</div>
                <textarea
                  name="comment"
                  type="text"
                  className="txt h-[100px]"
                  placeholder="Comentário..."
                  value={createIntegration.comment}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="row1 mt-12">
              {!integrationAccessLoading && (
                <div className="field flex">
                  <div className="row6 w-full">
                    {integrationAccessData?.data.map((item, index) => (
                      <div className="item flex aic mr-7" key={index}>
                        <input
                          type="checkbox"
                          name="integrationaccess"
                          value={`${item.name}`}
                          onChange={(e) => handleIntegrationAccess(e, item)}
                          checked={
                            createIntegration?.integrationaccess?.filter(
                              (x) => x.id === item.id
                            ).length > 0
                              ? true
                              : false
                          }
                          className="check-box mr-2 h-5 w-5 cursor-pointer"
                        />
                        <div className="check-box-lbl s16">{item.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Criar"}
                className="btn button"
              />
            </div>
          </>
          {data.isLoading ? <Loader /> : <></>}
        </div>
      </div>
    </form>
  );
};

export default NewIntegration;
