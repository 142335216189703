import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Document, Page } from "react-pdf";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import { DeleteIcon, EditIcon, DownloadIcon } from "../../assets/icons";
import { useGetFinanceListQuery } from "../../store/services/financeService";

const FinancialPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModuleId, setDeleteModuleId] = useState("");
  const { data, isLoading, isFetching } = useGetFinanceListQuery();
  console.log("what is Invoice data", data);

  const renderColumns = () => {
    return [
      {
        name: "Name",
        sortable: true,
        selector: (row) => row?.name,
      },
      {
        name: "CNPJ",
        sortable: true,
        selector: (row) => row?.cnjp,
      },
      {
        name: "Active Associates",
        sortable: true,
        selector: (row) => row?.totalactiveassociats,
      },
      {
        name: "Revenue",
        sortable: true,
        selector: (row) => row?.sum_of_plan,
      },
      {
        name: "Unitary Value",
        sortable: true,
        selector: (row) => row?.unitary_value,
      },

      {
        name: "Status",
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.status).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row.status).toUpperCase()}
              </span>
            )}
            {String(row.status).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.status).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },

      {
        name: "Due Date",
        sortable: true,
        selector: (row) => row?.expiration,
      },

      {
        name: "More",
        button: true,
        cell: (row) => (
          <>
            <div className="icon cursor-pointer text-lg font-medium underline hover:text-indigo-500">
              Detail
            </div>
          </>
        ),
      },
    ];
  };

  return (
    <div className="financial-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-title">Financial List</div>
        <div className="table-blk flex flex-col">
          {/* <div className="action flex items-center justify-end">
            <Link to={"/dashboard/new-financial"} className="btn-new button">
              Add New Financial
            </Link>
          </div> */}
          <div className="tbl flex flex-col">
            <>
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true}
              />
            </>
            {isLoading || isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialPage;
