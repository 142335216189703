import React from "react";

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M10.08 17.4a.6.6 0 00.6.6h9.6a.6.6 0 00.6-.6V6.6a.6.6 0 00-.6-.6h-9.6a.6.6 0 00-.6.6V9a.6.6 0 11-1.2 0V6.6a1.8 1.8 0 011.8-1.8h9.6a1.8 1.8 0 011.8 1.8v10.8a1.8 1.8 0 01-1.8 1.8h-9.6a1.8 1.8 0 01-1.8-1.8V15a.6.6 0 111.2 0v2.4z"
        clipRule="evenodd"
      ></path>
      <path
        fillRule="evenodd"
        d="M3.054 12.424a.6.6 0 010-.85l3.6-3.6a.6.6 0 11.85.85L4.927 11.4H15.48a.6.6 0 110 1.2H4.927l2.577 2.575a.6.6 0 11-.85.85l-3.6-3.6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default LogoutIcon;
