import React from "react";

function FinanceIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M2 2h1.25v18.75H22V22H2V2zm18.521 3.891a.626.626 0 01.088.88l-5.625 6.875a.626.626 0 01-.925.046l-3.234-3.233-4.57 6.284a.625.625 0 01-1.01-.735l5-6.875a.625.625 0 01.948-.075l3.26 3.262 5.188-6.341a.626.626 0 01.88-.088z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FinanceIcon;
