import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CameraIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import { useCreateNewCategoryMutation } from "../../store/services/categoryService";

const NewCategory = () => {
  // focus(".txtBox");
  const navigate = useNavigate();
  const [img, setImg] = useState();
  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [createCategory, setCreateCategory] = useState({
    name: "",
    description: "",
    status: "",
    image: "",
  });
  console.log("form data", createCategory);

  const handleInput = (e) => {
    setCreateCategory({ ...createCategory, [e.target.name]: e.target.value });
  };
  const handleInputImage = (e) => {
    // setCreateCategory({
    //   ...createCategory,
    //   [e.target.name]: img,
    // });
  };
  useEffect(() => {
    setCreateCategory({
      ...createCategory,
      image: img,
    });
  }, [img]);
  const handleStatus = (e) => {
    setCreateCategory({ ...createCategory, status: e.value });
    setSelectedStatus({ display: e.display, value: e.value });
  };

  const [saveCategory, data] = useCreateNewCategoryMutation();
  const errors = data?.error?.data ? data?.error?.data.message : null;

  const submitCreateCategory = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", createCategory.name);
    formData.append("status", createCategory.status);
    formData.append("description", createCategory.description);
    formData.append("image", img);
    saveCategory(formData);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/dashboard/categories");
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={submitCreateCategory} className="new-category flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Criar Nova Categoria</div>

          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome *</div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createCategory.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                <Select
                  defaultValue={selectedStatus}
                  onChange={handleStatus}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={statusData}
                  className="select"
                  id="status"
                  name="status"
                  placeholder="Selecionar Status"
                />
              </div>
            </div>
            <div className="row pt-6">
              <div className="field flex flex-col ">
                <div className="lbl">Descrição</div>
                <textarea
                  id="description"
                  name="description"
                  type="text"
                  className="txt h-[160px]"
                  placeholder="Descrição"
                  value={createCategory.description}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Imagem da Categoria</div>
                <div
                  className="img-box flex flex-col items-center justify-center"
                  onClick={() => document.getElementById("upload_img").click()}
                >
                  {img ? (
                    <img src={URL.createObjectURL(img)} className="img" />
                  ) : (
                    <>
                      <CameraIcon />
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    name="image"
                    id="upload_img"
                    className="select-file cleanbtn"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      console.log("img url", e.target.files);
                      setImg(e.target.files[0]);
                      handleInputImage(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Criar"}
                className="btn button"
              />
            </div>
          </>
          {data.isLoading ? <Loader /> : <></>}
        </div>
      </div>
    </form>
  );
};

export default NewCategory;
