import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { CameraIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import { useCreateNewModuleMutation } from "../../store/services/moduleService";
import { useGetCategoriesQuery } from "../../store/services/categoryService";
import { useGetIntegrationsListQuery } from "../../store/services/integrationService";

const NewModule = () => {
  // focus(".txtBox");
  const {
    data: categoriesList,
    isLoading: categoriesLoading,
    isFetching: categoriesFetching,
  } = useGetCategoriesQuery();

  const {
    data: integrationList,
    isLoading: integrationLoading,
    isFetching: integrationFetching,
  } = useGetIntegrationsListQuery();

  const billingList = [
    { value: 1, display: 1 },
    { value: 2, display: 2 },
    { value: 3, display: 3 },
    { value: 4, display: 4 },
    { value: 5, display: 5 },
    { value: 6, display: 6 },
    { value: 7, display: 7 },
    { value: 8, display: 8 },
    { value: 9, display: 9 },
    { value: 10, display: 10 },
    { value: 11, display: 11 },
    { value: 12, display: 12 },
    { value: 13, display: 13 },
    { value: 14, display: 14 },
    { value: 15, display: 15 },
    { value: 16, display: 16 },
    { value: 17, display: 17 },
    { value: 18, display: 18 },
    { value: 19, display: 19 },
    { value: 20, display: 20 },
    { value: 21, display: 21 },
    { value: 22, display: 22 },
    { value: 23, display: 23 },
    { value: 24, display: 24 },
    { value: 25, display: 25 },
    { value: 26, display: 26 },
    { value: 27, display: 27 },
    { value: 28, display: 28 },
    { value: 29, display: 29 },
    { value: 30, display: 30 },
  ];

  const navigate = useNavigate();
  const [img, setImg] = useState();
  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];

  const [selectedStatus, setSelectedStatus] = useState();
  const [createModule, setCreateModule] = useState({
    name: "",
    status: "",
    categories_id: "",
    integrations_id: "",
    contact: "",
    website: "",
    billing: "",
    unitary_value: "",
    amount_paid: "",
    description: "",
    note: "",
    image: "",
  });

  const handleInput = (e) => {
    setCreateModule({ ...createModule, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setCreateModule({
      ...createModule,
      image: img,
    });
  }, [img]);

  const handleStatus = (e) => {
    setCreateModule({ ...createModule, status: e.value });
    setSelectedStatus({ display: e.display, value: e.value });
  };

  const handleCategory = (e) => {
    setCreateModule({ ...createModule, categories_id: e.id });
  };

  const handleIntegration = (e) => {
    setCreateModule({ ...createModule, integrations_id: e.id });
  };
  const handleBilling = (e) => {
    setCreateModule({ ...createModule, billing: e.value });
  };

  const [saveModule, data] = useCreateNewModuleMutation();

  const submitCreateModule = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", createModule.name);
    formData.append("status", createModule.status);
    formData.append("categories_id", createModule.categories_id);
    formData.append("integrations_id", createModule.integrations_id);
    formData.append("website", createModule.website);
    formData.append("note", createModule.note);
    formData.append("contact", createModule.contact);
    formData.append("billing", createModule.billing);
    formData.append("unitary_value", createModule.unitary_value);
    formData.append("amount_paid", createModule.amount_paid);
    formData.append("description", createModule.description);
    formData.append("image", createModule.image);
    saveModule(formData);
  };

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/dashboard/modules");
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={submitCreateModule} className="new-module flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Criar Novo Módulo</div>

          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome *</div>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createModule.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                <Select
                  // defaultValue={selectedStatus}
                  onChange={handleStatus}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={statusData}
                  className="select"
                  id="status"
                  name="status"
                  placeholder="Selecionar Status"
                />
              </div>

              <div className="field flex flex-col">
                <div className="lbl">Categoria</div>
                {!categoriesFetching && (
                  <Select
                    // defaultValue={selectedStatus}
                    onChange={handleCategory}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={categoriesList?.data}
                    className="select"
                    name="categories_id"
                    placeholder="Selecionar Categoria"
                  />
                )}
              </div>

              <div className="field flex flex-col">
                <div className="lbl">Integração</div>
                {!integrationFetching && (
                  <Select
                    // defaultValue={selectedStatus}
                    onChange={handleIntegration}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={integrationList?.data}
                    className="select"
                    name="integrations_id"
                    placeholder="Selecionar Integração"
                  />
                )}
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Suporte Técnico</div>
                <input
                  name="contact"
                  type="number"
                  className="txt"
                  placeholder="Suporte Técnico"
                  value={createModule.contact}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Website</div>
                <input
                  name="website"
                  type="url"
                  className="txt"
                  placeholder="https://google.com"
                  value={createModule.website}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="row3">
              <div className="field flex flex-col">
                <div className="lbl">Vencimento</div>
                <Select
                  onChange={handleBilling}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={billingList}
                  className="select"
                  name="billing"
                  placeholder="Selecionar Vencimento"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Valor Unitário (R$)</div>
                <input
                  name="unitary_value"
                  type="number"
                  className="txt"
                  placeholder="Valor Unitário"
                  value={createModule.unitary_value}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Valor Pago (R$)</div>
                <input
                  name="amount_paid"
                  type="number"
                  className="txt"
                  placeholder="Valor Pago"
                  value={createModule.amount_paid}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="row">
              <div className="field flex flex-col ">
                <div className="lbl">Descrição</div>
                <textarea
                  id="description"
                  name="description"
                  type="text"
                  className="txt h-[160px]"
                  placeholder="Descrição"
                  value={createModule.description}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col ">
                <div className="lbl">Notas</div>
                <textarea
                  name="note"
                  type="text"
                  className="txt h-[160px]"
                  placeholder="Notas.."
                  value={createModule.note}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Logomarca</div>
                <div
                  className="img-box flex flex-col items-center justify-center"
                  onClick={() => document.getElementById("upload_img").click()}
                >
                  {img ? (
                    <img src={URL.createObjectURL(img)} className="img" />
                  ) : (
                    <>
                      <CameraIcon />
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    name="image"
                    id="upload_img"
                    className="select-file cleanbtn"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setImg(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Criar"}
                className="btn button"
              />
            </div>
          </>
          {categoriesFetching || integrationFetching || data.isLoading ? (
            <Loader />
          ) : (
            <></>
          )}
        </div>
      </div>
    </form>
  );
};

export default NewModule;
