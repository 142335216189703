import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CameraIcon, EditIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import {
  useFetchSettingDataQuery,
  useUpdateSettingMutation,
} from "../../store/services/settingService";

const Setting = () => {
  const { data, isLoading, isFetching } = useFetchSettingDataQuery();
  const [enableEdit, setEnableEdit] = useState(true);
  const [icon, setIcon] = useState();
  const [logo, setLogo] = useState();
  const [settingValues, setSettingValues] = useState({
    color: "",
    logo: "",
    icon: "",
  });

  const handleInput = (e) => {
    setSettingValues({ ...settingValues, [e.target.name]: e.target.value });
  };
  const handleInputImage = (e) => {
    setSettingValues({
      ...settingValues,
      [e.target.name]: e.target.files[0],
    });
  };
  useEffect(() => {
    if (!isFetching) {
      setSettingValues(data?.data);
    }
  }, [isFetching]);

  const [updateSettingData, response] = useUpdateSettingMutation();

  const submitUpdateSetting = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("color", settingValues.color);
    formData.append("logo", settingValues.logo);
    formData.append("icon", settingValues.icon);
    formData.append("_method", "PATCH");
    updateSettingData(formData);
  };

  useEffect(() => {
    if (response?.isSuccess) {
      setEnableEdit(true);
    }
  }, [response?.isSuccess]);

  return (
    <div className="setting-page">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-block flex flex-col">
          <div className="page-hdr flex items-center justify-between">
            <div className="page-title">Configurações</div>
            <div className="edit-action flex items-center justify-center">
              <button
                className="btn-edit flex items-center justify-center"
                onClick={(e) => setEnableEdit(!enableEdit)}
              >
                <div className="btn-icon flex items-center justify-center">
                  <EditIcon />
                </div>
                <div className="btn-lbl ml-3">
                  {enableEdit ? "Habilitar Edição" : "Desabilitar Edição"}
                </div>
              </button>
            </div>
          </div>
          <div className="form-block flex flex-col">
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Icon</div>
                <button
                  className="img-box flex flex-col items-center justify-center"
                  onClick={() => document.getElementById("upload_icon").click()}
                  disabled={enableEdit}
                >
                  {icon ? (
                    <img src={URL.createObjectURL(icon)} className="img" />
                  ) : settingValues?.icon ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}${settingValues?.icon}`}
                      className="img"
                    />
                  ) : (
                    <>
                      <img
                        src={`${process.env.REACT_APP_API_URL}${settingValues?.icon}`}
                        className="img"
                      />
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    name="icon"
                    id="upload_icon"
                    className="select-file cleanbtn"
                    onChange={(e) => {
                      setIcon(e.target.files[0]);
                      handleInputImage(e);
                    }}
                    disabled={enableEdit}
                  />
                </button>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Logomarca</div>
                <button
                  className="img-box flex flex-col items-center justify-center"
                  onClick={() => document.getElementById("upload_logo").click()}
                  disabled={enableEdit}
                >
                  {logo ? (
                    <img src={URL.createObjectURL(logo)} className="img" />
                  ) : settingValues?.logo ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}${settingValues?.logo}`}
                      className="img"
                    />
                  ) : (
                    <>
                      <img
                        src={`${process.env.REACT_APP_API_URL}${settingValues?.logo}`}
                        className="img"
                      />
                    </>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    title=""
                    name="logo"
                    id="upload_logo"
                    className="select-file cleanbtn"
                    onChange={(e) => {
                      setLogo(e.target.files[0]);
                      handleInputImage(e);
                    }}
                    disabled={enableEdit}
                  />
                </button>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Cor</div>
                <input
                  name="color"
                  type="text"
                  className="txt"
                  placeholder="#FFFFFF"
                  value={settingValues?.color}
                  onChange={handleInput}
                  disabled={enableEdit}
                />
              </div>
            </div>
            {!enableEdit && (
              <div className="action flex aic justify-end">
                <button
                  type="submit"
                  className="btn button"
                  onClick={(e) => submitUpdateSetting(e)}
                >
                  Atualizar
                </button>
              </div>
            )}
            {isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
