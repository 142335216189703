import React from "react";

function CronIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M14.5 5.75c0 1.641-.38 3.135-1 4.25a2.489 2.489 0 00-1.855-.475c-.35-1.227-1.138-2.55-2.298-3.711a10.46 10.46 0 00-.614-.568A7.47 7.47 0 0112 4.5c.864 0 1.694.146 2.467.415.022.274.034.553.034.835zm0 6.25c0 .091-.005.182-.015.271 1.272-.024 2.75-.441 4.163-1.257.244-.141.481-.292.713-.451a7.505 7.505 0 00-3.163-4.78c-.05.27-.11.536-.181.8-.425 1.587-1.18 2.933-2.07 3.85.346.428.553.975.553 1.567zm-.172.91a2.509 2.509 0 01-1.337 1.386c.656 1.088 1.756 2.157 3.169 2.971.25.146.502.278.756.397a7.482 7.482 0 002.566-5.139c-.26.091-.526.175-.801.249-1.58.424-3.117.445-4.353.137v-.001zM12 14.5a2.49 2.49 0 01-1.5-.5c-.617 1.113-.994 2.602-.994 4.239 0 .287.012.571.033.847A7.484 7.484 0 0012 19.5a7.47 7.47 0 003.28-.753 10.447 10.447 0 01-.622-.572c-1.156-1.158-1.944-2.476-2.295-3.7A2.656 2.656 0 0112 14.5zM9.5 12c0-.1.007-.2.018-.299-1.275.021-2.756.439-4.175 1.259-.243.14-.48.29-.71.449a7.504 7.504 0 003.156 4.798c.051-.274.113-.546.185-.816.425-1.583 1.175-2.927 2.063-3.843A2.485 2.485 0 019.5 12zm-4.183-.79c1.584-.425 3.122-.444 4.36-.134a2.509 2.509 0 011.337-1.375c-.655-1.092-1.757-2.166-3.176-2.985-.242-.14-.49-.27-.742-.39A7.484 7.484 0 004.52 11.46c.257-.093.522-.175.796-.249zM12 13.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"></path>
      <path d="M12 20.75a8.75 8.75 0 110-17.5 8.75 8.75 0 010 17.5zM12 22a10 10 0 100-20 10 10 0 000 20z"></path>
    </svg>
  );
}

export default CronIcon;
