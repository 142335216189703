import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import { DeleteIcon, EditIcon } from "../../assets/icons";
import { useGetCronsQuery } from "../../store/services/cronService";
import DeleteCron from "./DeleteCron";

const CronPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModuleId, setDeleteModuleId] = useState("");
  const { data, isLoading, isFetching } = useGetCronsQuery();

  const renderColumns = () => {
    return [
      {
        name: "Nome",
        sortable: true,
        selector: (row) => row?.name,
      },
      {
        name: "Status",
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.status).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row.status).toUpperCase()}
              </span>
            )}
            {String(row.status).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.status).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },
      {
        name: "Frequencia",
        sortable: true,
        selector: (row) => row?.frequency,
      },
      {
        name: "Integração",
        sortable: true,
        selector: (row) => row?.integrations_id,
      },

      {
        name: "Link",
        grow: 2,
        cell: (row) => (
          <>
            <a href={row.link} target="_blank">
              {row.link}
            </a>
          </>
        ),
        // selector: (row) => row?.link,
      },
      {
        name: "Ação",
        button: true,
        cell: (row) => (
          <>
            <Link
              to={`/dashboard/cron-update/${row.id}`}
              className="icon cursor-pointer"
            >
              <EditIcon />
            </Link>
            <div
              className="icon cursor-pointer ml-3"
              onClick={(e) => {
                setOpen(true);
                setDeleteModuleId({ id: row.id, name: row.name });
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ),
      },
    ];
  };

  return (
    <div className="modules-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-title">Lista de Crons</div>
        <div className="table-blk flex flex-col">
          <div className="action flex items-center justify-end">
            <Link to={"/dashboard/new-cron"} className="btn-new button">
              Add Novo Cron
            </Link>
          </div>
          <div className="tbl flex flex-col">
            <>
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true}
              />
            </>
            {isLoading || isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DeleteCron setOpen={setOpen} data={deleteModuleId} />
      </Modal>
    </div>
  );
};

export default CronPage;
