import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { CameraIcon, EditIcon, DeleteIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import DeletePlan from "./DeletePlan";
import UpdatePlan from "./UpdatePlan";
import Toggle from "../../components/Toggle";
import { toast } from "react-toastify";

import {
  useGetCompaniesQuery,
  useCreateCompanyPlanMutation,
  useGetSingeCompanyPlansQuery,
  useFetchCompanyQuery,
} from "../../store/services/companyService";
import { useGetModulesQuery } from "../../store/services/moduleService";

const CompanyPlanList = () => {
  const { id } = useParams();
  const [planId, setPlanId] = useState();
  const [refreshPage, setRefreshPage] = useState("0");
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [deleteModuleId, setDeleteModuleId] = useState("");
  const {
    data: companyData,
    isLoading: companyLoading,
    isFetching: companyFetching,
  } = useFetchCompanyQuery(id);

  const {
    data: currentCompanyPlansList,
    isLoading: currentCompanyPlansLoading,
    isFetching: currentCompanyPlansFetching,
  } = useGetSingeCompanyPlansQuery(id);

  const renderColumns = () => {
    return [
      {
        name: "Nome do plano",
        grow: 1,
        sortable: true,
        cell: (row) => (
          <>
            <div className="uppercase">{row?.name ? row?.name : "-"}</div>
          </>
        ),
      },
      {
        name: "Status",
        grow: 1,
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.status).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row.status).toUpperCase()}
              </span>
            )}
            {String(row.status).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.status).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },
      {
        name: "Tipo de pacote",
        grow: 1,
        sortable: true,
        cell: (row) => (
          <>
            <div className="uppercase">
              {row?.pacakage_type ? row?.pacakage_type : "-"}
            </div>
          </>
        ),
      },
      {
        name: "Nome do pacote",
        grow: 1,
        cell: (row) => (
          <div className="plans-list-section flex flex-col">
            {row?.pacakages.length ? (
              <>
                <div className={`packages-box flex items-cente`}>
                  {row?.pacakages.map((item, index) => (
                    <div key={index} className="package-name">
                      {item.data}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>-</>
            )}
          </div>
        ),
      },
      {
        name: "Ação",
        grow: 1.5,
        center: true,
        button: true,
        cell: (row) => (
          <>
            <div
              className="icon cursor-pointer"
              onClick={(e) => {
                setOpenUpdate(true);
                setPlanId(row.id);
              }}
            >
              <EditIcon />
            </div>
            <div
              className="icon cursor-pointer ml-3"
              onClick={(e) => {
                setOpen(true);
                setDeleteModuleId({ id: row.id, name: row.name });
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ),
      },
    ];
  };

  useEffect(() => {
    if (refreshPage === "1") {
      // window.location.reload();
      setRefreshPage("0");
    }
  }, [refreshPage]);

  return (
    <div className="new-company flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Atualizar planos</div>
          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Planos de empresas selecionadas</div>
                <input
                  name="pName"
                  type="text"
                  className="txt"
                  placeholder="Nome do plano"
                  disabled={true}
                  value={companyData?.data ? companyData?.data.name : "-"}
                />
                {/* {!companiesFetching && (
                  <Select
                    // defaultValue={selectedStatus}
                    onChange={handleCompany}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={companiesList?.data}
                    className="select"
                    id="company_id"
                    name="company_id"
                    placeholder="selecione a empresa"
                  />
                )} */}
              </div>
            </div>
            <div className="separator"></div>
            <div className="table-blk flex flex-col">
              <div className="page-title">Lista de planos</div>
              <div className="tbl flex flex-col">
                <>
                  <DataTable
                    columns={renderColumns()}
                    data={currentCompanyPlansList?.data}
                    responsive={true}
                    pagination={true}
                    paginationPerPage={"5"}
                    paginationRowsPerPageOptions={[
                      "5",
                      "10",
                      "15",
                      "20",
                      "25",
                      "30",
                    ]}
                  />
                </>
                {companyLoading ||
                companyFetching ||
                currentCompanyPlansFetching ||
                currentCompanyPlansLoading ? (
                  <Loader />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
          {companyFetching ? <Loader /> : <></>}
        </div>
        <Modal open={open} onClose={() => setOpen(false)}>
          <DeletePlan setOpen={setOpen} data={deleteModuleId} />
        </Modal>
        <Modal open={openUpdate} onClose={() => setOpenUpdate(false)}>
          <UpdatePlan
            setOpenUpdate={setOpenUpdate}
            planId={planId}
            setRefreshPage={setRefreshPage}
          />
        </Modal>
      </div>
    </div>
  );
};

export default CompanyPlanList;
