import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOpenSidebar } from "../../store/reducers/globalReducer";
import { logout } from "../../store/reducers/authReducer";
import {
  CategoryIcon,
  ChartIcon,
  FinanceIcon,
  FlagIcon,
  HomeIcon,
  ImageCardIcon,
  IntegrationIcon,
  MenuIcon,
  SearchIcon,
  SettingIcon,
  LogoutIcon,
  CloseIcon,
  CronIcon,
  CheckboxIcon,
  UsersIcon,
} from "../../assets/icons";

const Sidebar = () => {
  const navigate = useNavigate();
  const { openSidebar } = useSelector((state) => state.globalReducer);

  const dispatch = useDispatch();
  const navBarItems = [
    { lbl: "Home", icon: <HomeIcon />, slug: "/dashboard/home" },
    {
      lbl: "Empresas",
      icon: <FlagIcon />,
      slug: "/dashboard/companies",
    },
    { lbl: "Associados", icon: <ChartIcon />, slug: "/dashboard/associates" },
    {
      lbl: "Categorias",
      icon: <CategoryIcon />,
      slug: "/dashboard/categories",
    },
    { lbl: "Módulos", icon: <ImageCardIcon />, slug: "/dashboard/modules" },
    {
      lbl: "Integrações",
      icon: <IntegrationIcon />,
      slug: "/dashboard/integrations",
    },
    { lbl: "Financial", icon: <FinanceIcon />, slug: "/dashboard/financial" },
    { lbl: "Cron", icon: <CronIcon />, slug: "/dashboard/cron" },
    { lbl: "Funcionários", icon: <UsersIcon />, slug: "/dashboard/employee" },
    // {
    //   lbl: "Permission",
    //   icon: <CheckboxIcon />,
    //   slug: "/dashboard/permission",
    // },
    { lbl: "Configurações", icon: <SettingIcon />, slug: "/dashboard/setting" },
  ];

  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      setOpenSidebar(false);
    });
  }, []);
  const userLogout = () => {
    dispatch(logout("admin-token"));
    navigate("/");
  };

  return (
    <div
      className={`sidebar-s  fixed rel anim ${openSidebar ? "full-open" : ""}`}
    >
      <div
        className={`side-block  flex col anim ${
          openSidebar ? "full-open" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={`hdr flex items-center ${openSidebar ? "" : "center"}`}>
          {openSidebar ? (
            <div
              className="icon-close flex aic jc"
              onClick={(e) => {
                localStorage.setItem("sidebar", false);
                dispatch(setOpenSidebar(false));
              }}
            >
              <CloseIcon />
            </div>
          ) : (
            <div
              className="icon-close flex aic jc h-full w-full"
              onClick={(e) => {
                localStorage.setItem("sidebar", true);
                dispatch(setOpenSidebar(true));
              }}
            >
              <MenuIcon />
            </div>
          )}
          {openSidebar && <div className="hdr-tag ml-5">Meu Painel</div>}
        </div>
        <div className="items flex aic flex-col">
          {/* <div className="list-item flex">
            <div className="icon flex items-start justify-center ">
              <SearchIcon />
            </div>
            {openSidebar && (
              <input
                type="text"
                className="input ml-3 font"
                placeholder="Search"
              />
            )}
          </div> */}
          <>
            {navBarItems.map((item, index) => (
              <Link to={item.slug} key={index} className="list-item flex">
                <div className="icon flex items-start justify-center ">
                  {item.icon}
                </div>
                {openSidebar && (
                  <div className="ml-3 li cfff font">{item.lbl}</div>
                )}
              </Link>
            ))}
          </>
          <div className="list-item flex" onClick={(e) => userLogout()}>
            <div className="icon flex items-start justify-center ">
              <LogoutIcon />
            </div>
            {openSidebar && <div className="ml-3 li cfff font">Sair</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
