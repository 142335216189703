import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryService = createApi({
  reducerPath: "categoryService",
  tagTypes: "category",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New Category
      createNewCategory: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-category",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["category"],
      }),

      //   Update the Category
      updateCategory: builder.mutation({
        query: ({ formData, uId }) => {
          return {
            url: `/api/update-category/${uId}`,
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["category"],
      }),
      //   Delete a Category
      deleteCategory: builder.mutation({
        query: (uId) => {
          return {
            url: `/api/delete-category/${uId}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["category"],
      }),

      //   Get the List of Categories
      getCategories: builder.query({
        query: () => {
          return {
            url: "/api/view-category",
            method: "GET",
          };
        },
        providesTags: ["category"],
      }),

      //   Get the Single Category Data
      fetchCategory: builder.query({
        query: (uId) => {
          return {
            url: `/api/view-category/${uId}`,
            method: "GET",
          };
        },
        providesTags: ["category"],
      }),
    };
  },
});

export const {
  useGetCategoriesQuery,
  useCreateNewCategoryMutation,
  useDeleteCategoryMutation,
  useFetchCategoryQuery,
  useUpdateCategoryMutation,
} = categoryService;

export default categoryService;
