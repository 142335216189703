import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Loader from "../../components/Loader";
import {
  useUpdateCronMutation,
  useFetchCronQuery,
} from "../../store/services/cronService";
import { useGetFrequenciesQuery } from "../../store/services/frequencyService";
import { useGetIntegrationsListQuery } from "../../store/services/integrationService";

const UpdateCron = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    data: fetchCron,
    isLoading: fetchCronLoading,
    isFetching: fetchCronFetching,
  } = useFetchCronQuery(id);

  const {
    data: frequenciesList,
    isLoading: frequenciesLoading,
    isFetching: frequenciesFetching,
  } = useGetFrequenciesQuery();

  const {
    data: integrationList,
    isLoading: integrationLoading,
    isFetching: integrationFetching,
  } = useGetIntegrationsListQuery();

  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];

  const [createCron, setCreateCron] = useState({
    name: "",
    status: "",
    frequency: "",
    integrations_id: "",
    link: "",
  });

  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedFrequency, setSelectedFrequency] = useState();
  const [selectedIntegration, setSelectedIntegration] = useState();

  const handleInput = (e) => {
    setCreateCron({ ...createCron, [e.target.name]: e.target.value });
  };

  const handleStatus = (e) => {
    setCreateCron({ ...createCron, status: e.value });
  };

  const handleFrequency = (e) => {
    setCreateCron({ ...createCron, frequency: e.id });
  };

  const handleIntegration = (e) => {
    setCreateCron({ ...createCron, integrations_id: e.id });
  };

  const [saveCron, data] = useUpdateCronMutation();

  const submitCreateCron = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", createCron.name);
    formData.append("status", createCron.status);
    formData.append("integrations_id", createCron.integrations_id);
    formData.append("frequency", createCron.frequency);
    formData.append("link", createCron.link);
    formData.append("_method", "PATCH");
    saveCron({ formData, id });
  };

  useEffect(() => {
    if (!fetchCronFetching) {
      setCreateCron(fetchCron?.data);
    }
  }, [fetchCron?.data]);

  useEffect(() => {
    setSelectedStatus({
      display: createCron?.status,
      value: createCron?.status,
    });
    setSelectedIntegration({
      name: createCron?.integration?.name,
      id: createCron?.integrations_id,
    });

    setSelectedFrequency({
      name: createCron?.frequency,
      id: createCron?.frequency,
    });
  }, [createCron]);

  useEffect(() => {
    if (data?.isSuccess) {
      navigate("/dashboard/cron");
    }
  }, [data?.isSuccess]);

  return (
    <form onSubmit={submitCreateCron} className="new-module flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Atualizar Cron</div>

          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Nome</div>
                <input
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome"
                  value={createCron.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status</div>
                <Select
                  defaultValue={selectedStatus}
                  onChange={handleStatus}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={statusData}
                  className="select"
                  id="status"
                  name="status"
                  placeholder="Selecionar Status"
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Integração</div>
                {!integrationFetching && (
                  <Select
                    defaultValue={selectedIntegration}
                    onChange={handleIntegration}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={integrationList?.data}
                    className="select"
                    name="integrations_id"
                    placeholder="Selecionar Integração"
                  />
                )}
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Frequencia</div>
                {!frequenciesFetching && (
                  <Select
                    defaultValue={selectedFrequency}
                    onChange={handleFrequency}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={frequenciesList?.data}
                    className="select"
                    name="frequency"
                    placeholder="Selecionar Frequencia"
                  />
                )}
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Link</div>
                <input
                  name="link"
                  type="url"
                  className="txt"
                  placeholder="https://google.com"
                  value={createCron.link}
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Atualizar"}
                className="btn button"
              />
            </div>
          </>
          {frequenciesFetching || integrationFetching || data.isLoading ? (
            <Loader />
          ) : (
            <></>
          )}
        </div>
      </div>
    </form>
  );
};

export default UpdateCron;
