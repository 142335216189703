import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import {
  DeleteIcon,
  EditIcon,
  DownloadIcon,
  ReportIcon,
} from "../../assets/icons";
import { useGetCompaniesQuery } from "../../store/services/companyService";
import DeleteCompany from "./DeleteCompany";

const EmployeesPage = () => {
  const [open, setOpen] = useState(false);
  const [deleteModuleId, setDeleteModuleId] = useState("");
  const { data, isLoading, isFetching } = useGetCompaniesQuery();
  // console.log("what is company data", data);

  const renderColumns = () => {
    return [
      {
        name: "Logo",
        grow: 0.5,
        cell: (row) => (
          <>
            <img
              src={`${process.env.REACT_APP_API_URL}${row.image}`}
              className="c-img"
            />
          </>
        ),
      },
      {
        name: "Nome",
        grow: 1,
        sortable: true,
        selector: (row) => row?.name,
      },
      {
        name: "Status",
        grow: 1,
        sortable: true,
        cell: (row) => (
          <div>
            {String(row.status).toLowerCase() === "active" && (
              <span className="status-tag success">
                {String(row.status).toUpperCase()}
              </span>
            )}
            {String(row.status).toLowerCase() === "inactive" && (
              <span className="status-tag secondary">
                {String(row.status).toUpperCase()}
              </span>
            )}
          </div>
        ),
      },
      {
        name: "CNPJ",
        grow: 1,
        sortable: true,
        selector: (row) => row?.cnjp,
      },
      {
        name: "Razão Social",
        grow: 1,
        sortable: true,
        selector: (row) => row?.company_name,
      },
      {
        name: "Endereço",
        grow: 1.3,
        center: true,
        sortable: true,
        selector: (row) => row?.address,
      },
      {
        name: "Website",
        grow: 1,
        sortable: true,
        selector: (row) => row?.website,
      },

      {
        name: "Ação",
        grow: 1.5,
        center: true,
        button: true,
        cell: (row) => (
          <>
            <a
              href={`${process.env.REACT_APP_API_URL}${row.contract}`}
              target="_blank"
              download="contract.pdf"
              className="icon cursor-pointer"
            >
              <DownloadIcon />
            </a>
            <Link
              to={`/dashboard/companyPlanList/${row.id}`}
              className="icon cursor-pointer mx-3"
            >
              <ReportIcon />
            </Link>
            <Link
              to={`/dashboard/company-update/${row.id}`}
              className="icon cursor-pointer mx-3"
            >
              <EditIcon />
            </Link>
            <div
              className="icon cursor-pointer"
              onClick={(e) => {
                setOpen(true);
                setDeleteModuleId({ id: row.id, name: row.name });
              }}
            >
              <DeleteIcon />
            </div>
          </>
        ),
      },
    ];
  };

  return (
    <div className="company-page flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="page-title">Lista de Empresas</div>

        <div className="table-blk flex flex-col">
          <div className="flex items-center justify-between w-full">
            <div className="action flex items-center justify-end">
              <Link to={"/dashboard/add-plan"} className="btn-new button">
                Adicionar planos
              </Link>
            </div>
            <div className="action flex items-center justify-end">
              <Link to={"/dashboard/new-company"} className="btn-new button">
                Add Nova Empresa
              </Link>
            </div>
          </div>
          <div className="tbl flex flex-col">
            <>
              <DataTable
                columns={renderColumns()}
                data={data?.data}
                responsive={true}
                pagination={true}
                paginationPerPage={"5"}
                paginationRowsPerPageOptions={[
                  "5",
                  "10",
                  "15",
                  "20",
                  "25",
                  "30",
                ]}
              />
            </>
            {isLoading || isFetching ? <Loader /> : <></>}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <DeleteCompany setOpen={setOpen} data={deleteModuleId} />
      </Modal>
    </div>
  );
};

export default EmployeesPage;
