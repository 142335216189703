import React from "react";

function ImageCardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M9.603 9a1.8 1.8 0 11-3.6 0 1.8 1.8 0 013.6 0z"></path>
      <path d="M4.2 4.8a1.8 1.8 0 00-1.8 1.8v10.8a1.8 1.8 0 001.8 1.8h15.6a1.8 1.8 0 001.8-1.8V6.6a1.8 1.8 0 00-1.8-1.8H4.2zM19.8 6a.6.6 0 01.6.6v7.2l-4.53-2.337a.6.6 0 00-.692.112l-4.452 4.452-3.192-2.126a.6.6 0 00-.756.074L3.603 16.8v.648A.605.605 0 013.6 17.4V6.6a.6.6 0 01.6-.6h15.6z"></path>
    </svg>
  );
}

export default ImageCardIcon;
