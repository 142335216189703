import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const companyService = createApi({
  reducerPath: "companyService",
  tagTypes: "company",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Upload Company Image
      uploadCompanyImage: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-company/image",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["company"],
      }),
      //   Create New company
      createNewCompany: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-company",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["company"],
      }),

      //   Create company Plan
      createCompanyPlan: builder.mutation({
        query: (data) => {
          return {
            url: "/api/create-plan",
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["company"],
      }),

      //   Update the company
      updateCompany: builder.mutation({
        query: (formData) => {
          return {
            url: `/api/update-company/${formData?.id}`,
            method: "PATCH",
            body: formData,
          };
        },
        invalidatesTags: ["company"],
      }),

      //   Update the company Plan
      updateCompanyPlan: builder.mutation({
        query: (data) => {
          return {
            url: `/api/update-plan/${data?.id}`,
            method: "PATCH",
            body: data,
          };
        },
        invalidatesTags: ["company"],
      }),

      //   Delete a company
      deleteCompany: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete-company/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["company"],
      }),
      //   Delete a company Plan
      deleteCompanyPlan: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete-plan/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["company"],
      }),

      //   Get the List of Companies
      getCompanies: builder.query({
        query: () => {
          return {
            url: "/api/view-company",
            method: "GET",
          };
        },
        providesTags: ["company"],
      }),

      //   Get the List of Companies Names
      getCompaniesNames: builder.query({
        query: () => {
          return {
            url: "/api/company",
            method: "GET",
          };
        },
        providesTags: ["company"],
      }),

      //   Get the List of Companies Plans
      getCompaniesPlans: builder.query({
        query: () => {
          return {
            url: "/api/plan",
            method: "GET",
          };
        },
        providesTags: ["company"],
      }),
      //   Get the Single Company Plans
      getSingeCompanyPlans: builder.query({
        query: (id) => {
          return {
            url: `/api/companyplan/${id}`,
            method: "GET",
          };
        },
        providesTags: ["company"],
      }),

      //   Get the Single company Data
      fetchCompany: builder.query({
        query: (id) => {
          return {
            url: `/api/view-company/${id}`,
            method: "GET",
          };
        },
        providesTags: ["company"],
      }),

      //   Get the Single company Plan Data
      fetchCompanyPlan: builder.query({
        query: (id) => {
          return {
            url: `/api/view-plan/${id}`,
            method: "GET",
          };
        },
        providesTags: ["company"],
      }),
    };
  },
});

export const {
  useGetCompaniesQuery,
  useGetCompaniesNamesQuery,
  useGetCompaniesPlansQuery,
  useGetSingeCompanyPlansQuery,
  useUploadCompanyImageMutation,
  useCreateNewCompanyMutation,
  useCreateCompanyPlanMutation,
  useDeleteCompanyMutation,
  useDeleteCompanyPlanMutation,
  useFetchCompanyQuery,
  useFetchCompanyPlanQuery,
  useUpdateCompanyMutation,
  useUpdateCompanyPlanMutation,
} = companyService;

export default companyService;
