import { configureStore } from "@reduxjs/toolkit";

import authService from "./services/authService";
import categoryService from "./services/categoryService";
import settingService from "./services/settingService";
import integrationService from "./services/integrationService";
import integrationAccessService from "./services/integrationAccessService";
import moduleService from "./services/moduleService";
import frequencyService from "./services/frequencyService";
import cronService from "./services/cronService";
import employeeService from "./services/employeeService";
import roleService from "./services/roleService";
import accessService from "./services/accessService";
import companyService from "./services/companyService";
import associateService from "./services/associateService";
import planService from "./services/planService";
import financeService from "./services/financeService";

import authReducer from "./reducers/authReducer";
import globalReducer from "./reducers/globalReducer";

const Store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [categoryService.reducerPath]: categoryService.reducer,
    [settingService.reducerPath]: settingService.reducer,
    [integrationService.reducerPath]: integrationService.reducer,
    [integrationAccessService.reducerPath]: integrationAccessService.reducer,
    [moduleService.reducerPath]: moduleService.reducer,
    [frequencyService.reducerPath]: frequencyService.reducer,
    [cronService.reducerPath]: cronService.reducer,
    [employeeService.reducerPath]: employeeService.reducer,
    [roleService.reducerPath]: roleService.reducer,
    [accessService.reducerPath]: accessService.reducer,
    [companyService.reducerPath]: companyService.reducer,
    [associateService.reducerPath]: associateService.reducer,
    [planService.reducerPath]: planService.reducer,
    [financeService.reducerPath]: financeService.reducer,

    authReducer: authReducer,
    globalReducer: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authService.middleware,
      categoryService.middleware,
      settingService.middleware,
      integrationService.middleware,
      integrationAccessService.middleware,
      moduleService.middleware,
      frequencyService.middleware,
      cronService.middleware,
      employeeService.middleware,
      roleService.middleware,
      accessService.middleware,
      companyService.middleware,
      associateService.middleware,
      planService.middleware,
      financeService.middleware,
    ]),
});

export default Store;
