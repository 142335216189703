import React from "react";

function DropDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      viewBox="0 0 24 24"
    >
      <path d="M5.25 8.625l6.75 6.75 6.75-6.75"></path>
    </svg>
  );
}

export default DropDownIcon;
