import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { CameraIcon } from "../../assets/icons";
import Loader from "../../components/Loader";
import Toggle from "../../components/Toggle";
import { toast } from "react-toastify";

import {
  useGetCompaniesQuery,
  useCreateCompanyPlanMutation,
} from "../../store/services/companyService";
import { useGetModulesQuery } from "../../store/services/moduleService";

const AddPlan = () => {
  const {
    data: companiesList,
    isLoading: companiesLoading,
    isFetching: companiesFetching,
  } = useGetCompaniesQuery();
  const {
    data: modulesList,
    isLoading: modulesLoading,
    isFetching: modulesFetching,
  } = useGetModulesQuery();

  const navigate = useNavigate();
  const [selection, setSelection] = useState("plan");

  const statusData = [
    { id: 1, display: "Active", value: "active" },
    { id: 2, display: "Inactive", value: "inactive" },
  ];

  const [selectedPackage, setSelectedPackage] = useState([]);
  const [createCompany, setCreateCompany] = useState({
    company_id: "",
    module_unitary_value: "",
    pacakage_type: "Plano",
    options: "1",
    name: "",
    status: "",
    pacakages: [],
    modules: [],
  });

  const handleInput = (e) => {
    setCreateCompany({ ...createCompany, [e.target.name]: e.target.value });
  };

  const handleCompany = (e) => {
    setCreateCompany({ ...createCompany, company_id: e.id });
  };

  const handleChangeOptions = (e) => {
    setCreateCompany({ ...createCompany, options: e.target.value });
  };

  const handlePlanStatus = (e) => {
    setCreateCompany({
      ...createCompany,
      status: e.value,
    });
    // setCreateCompany({
    //   ...createCompany,
    //   plans: {
    //     ...createCompany.plans,
    //     status: e.value,
    //   },
    // });
  };

  const [saveCompany, data] = useCreateCompanyPlanMutation();

  const submitCreateCompany = (e) => {
    e.preventDefault();
    saveCompany(createCompany);
  };

  console.log("createCompany data", createCompany);

  useEffect(() => {
    if (data?.isSuccess) {
      toast.success("Company Created Successfully!");
      navigate("/dashboard/companies");
    }
  }, [data?.isSuccess]);

  useEffect(() => {
    if (data?.isError) {
      const errorMessages = Object.values(data?.error?.data?.message).reduce(
        (acc, val) => acc.concat(val),
        []
      );

      errorMessages.forEach((error) => {
        toast.error(error);
      });
    }
  }, [data?.isError]);

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.target.value;
      if (!value.trim()) return;
      setSelectedPackage([...selectedPackage, { data: value }]);
      e.target.value = "";
    }
  }

  function removeTag(index) {
    setSelectedPackage(selectedPackage.filter((el, i) => i !== index));
  }
  useEffect(() => {
    setCreateCompany({ ...createCompany, pacakages: selectedPackage });
  }, [selectedPackage]);

  const [totalUnitaryValue, setTotalUnitaryValue] = useState([]);
  // Handle Integration Access
  const selectedModulesList = createCompany.modules;
  const UnitaryValueSum = totalUnitaryValue.reduce(
    (acc, obj) => acc + obj.unitary_value,
    0
  );
  useEffect(() => {
    setCreateCompany({
      ...createCompany,
      module_unitary_value: UnitaryValueSum,
    });
  }, [UnitaryValueSum]);

  const handleModules = (e, item) => {
    // Destructuring
    const { value, checked } = e.target;

    // Case 1 : The user checks the box
    if (checked) {
      selectedModulesList.push({
        mId: item.id,
        mName: item.name,
      });

      totalUnitaryValue.push({
        mId: item.id,
        unitary_value: item.unitary_value,
      });

      setCreateCompany({
        ...createCompany,
        modules: selectedModulesList,
      });
    }
    // Case 2  : The user unchecked the box
    else {
      let newSelectedModulesList = selectedModulesList.filter(function (
        filterItem
      ) {
        return filterItem.mId !== item.id;
      });
      setCreateCompany({
        ...createCompany,
        modules: newSelectedModulesList,
      });

      // Remove unitary_value
      setTotalUnitaryValue(
        totalUnitaryValue.filter(function (filterItem) {
          return filterItem.mId !== item.id;
        })
      );
    }
  };

  return (
    <form onSubmit={submitCreateCompany} className="new-company flex">
      <div className="wrap wrapWidth flex flex-col">
        <div className="form-block flex flex-col">
          <div className="page-title">Adicionar planos</div>
          <>
            <div className="row">
              <div className="field flex flex-col">
                <div className="lbl">Selecione a empresa</div>
                {!companiesFetching && (
                  <Select
                    // defaultValue={selectedStatus}
                    onChange={handleCompany}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    options={companiesList?.data}
                    className="select"
                    id="company_id"
                    name="company_id"
                    placeholder="selecione a empresa"
                  />
                )}
              </div>
            </div>
            <div className="separator"></div>

            {/* Plan Section */}
            <div className="row my-5">
              <div className="field flex flex-col">
                <div className="lbl">Nome do Plano *</div>
                <input
                  name="name"
                  type="text"
                  className="txt"
                  placeholder="Nome do plano"
                  value={createCompany.name}
                  onChange={handleInput}
                />
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Status do Plano</div>
                <Select
                  onChange={handlePlanStatus}
                  getOptionLabel={(option) => option.display}
                  getOptionValue={(option) => option.value}
                  options={statusData}
                  className="select"
                  id="status"
                  name="status"
                  placeholder="Selecione o Status"
                />
              </div>
            </div>
            <div className="row1">
              <>
                <div className="lbl">Selecione o Plano</div>
                <div className="select-item flex aic">
                  <div
                    className="radio-select flex je cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelection("plan");
                      setCreateCompany({
                        ...createCompany,
                        pacakage_type: "Plano",
                      });
                    }}
                  >
                    <button
                      className={`cleanbtn radio-btn rel ${
                        selection === "plan" ? "on" : ""
                      }`}
                    />
                    <div className="lbl">Plano</div>
                  </div>
                  <div
                    className="radio-select flex je cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelection("product");
                      setCreateCompany({
                        ...createCompany,
                        pacakage_type: "Produto",
                      });
                    }}
                  >
                    <button
                      className={`cleanbtn radio-btn rel ${
                        selection === "product" ? "on" : ""
                      }`}
                    />
                    <div className="lbl">Produto</div>
                  </div>
                  <div
                    className="radio-select flex je cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelection("tipping");
                      setCreateCompany({
                        ...createCompany,
                        pacakage_type: "Tombamento",
                        pacakages: [],
                      });
                    }}
                  >
                    <button
                      className={`cleanbtn radio-btn rel ${
                        selection === "tipping" ? "on" : ""
                      }`}
                    />
                    <div className="lbl">Tombamento</div>
                  </div>
                </div>
                {selection === "plan" || selection === "product" ? (
                  <div className="field flex flex-col">
                    <div className="lbl">Pacotes (insira e aperte Enter)</div>
                    <div className="tags-input-container">
                      {selectedPackage?.map((tag, index) => (
                        <div className="tag-item" key={index}>
                          <span className="text">{tag?.data}</span>
                          <span
                            className="close"
                            onClick={() => removeTag(index)}
                          >
                            &times;
                          </span>
                        </div>
                      ))}
                      <input
                        onKeyDown={handleKeyDown}
                        type="text"
                        className="tags-input"
                        placeholder={`${
                          selectedPackage?.length == 0
                            ? "Sem pacotes"
                            : "Insira algo"
                        }`}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            </div>

            <div className="row1">
              <div className="modules-list flex items-center flex-wrap">
                <div className="row5">
                  {modulesList?.data.map((items, index) => (
                    <div key={index} className="module-item flex items-center">
                      <input
                        type="checkbox"
                        name="modules"
                        value={`${items.name}`}
                        onChange={(e) => handleModules(e, items)}
                        checked={
                          createCompany?.modules?.filter(
                            (x) => x.mId === items.id
                          ).length > 0
                            ? true
                            : false
                        }
                        className="check-box mr-2 h-5 w-5 cursor-pointer"
                      />
                      <div className="lbl">
                        {items?.name ? items?.name : ""}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="field flex flex-col justify-between">
                <div className="lbl">Opção*</div>
                <div className="flex items-center w-full">
                  <input
                    name="options"
                    type="range"
                    className="txt w-[60%]"
                    placeholder="Nome do Plano"
                    min={0}
                    max={
                      modulesList?.data?.length
                        ? modulesList?.data?.length
                        : "0"
                    }
                    value={createCompany.options}
                    onChange={handleChangeOptions}
                  />
                  <div className="ml-3"> {createCompany?.options}</div>
                </div>
              </div>
              <div className="field flex flex-col">
                <div className="lbl">Valor Unitário</div>
                <input
                  name="module_unitary_value"
                  type="text"
                  className="txt"
                  placeholder="Unitary Value"
                  value={`R$ ${
                    UnitaryValueSum ? UnitaryValueSum.toFixed(2) : "0"
                  }`}
                  disabled={true}
                  // onChange={handlePlan}
                />
              </div>
            </div>

            <div className="action flex aic justify-end">
              <input
                type="submit"
                value={data.isLoading ? "Loading..." : "Criar"}
                className="btn button"
              />
            </div>
          </>
          {modulesFetching || companiesFetching || data.isLoading ? (
            <Loader />
          ) : (
            <></>
          )}
        </div>
      </div>
    </form>
  );
};

export default AddPlan;
