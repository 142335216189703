import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const integrationAccessService = createApi({
  reducerPath: "integrationAccessService",
  tagTypes: "integrationAccess",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Update the Integration Access
      updateSetting: builder.mutation({
        query: (updatedData) => {
          console.log("What is update Data", updatedData);
          return {
            url: `/api/update-setting`,
            method: "POST",
            body: updatedData,
          };
        },
        invalidatesTags: ["integrationAccess"],
      }),

      //   Get Integration Access list
      getIntegrationAccess: builder.query({
        query: () => {
          return {
            url: "/api/view-integration-access",
            method: "GET",
          };
        },
        providesTags: ["integrationAccess"],
      }),
    };
  },
});

export const { useGetIntegrationAccessQuery, useUpdateSettingMutation } =
  integrationAccessService;

export default integrationAccessService;
