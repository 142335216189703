import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const frequencyService = createApi({
  reducerPath: "frequencyService",
  tagTypes: "frequency",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,

    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const authorization = reducers.authReducer?.adminToken;
      headers.set(
        "authorization",
        `Bearer ${authorization}` ? `Bearer ${authorization}` : ""
      );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,

  endpoints: (builder) => {
    return {
      //   Create New frequency
      createNewFrequency: builder.mutation({
        query: (formData) => {
          return {
            url: "/api/create-frequency",
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["frequency"],
      }),

      //   Update the frequency
      updateFrequency: builder.mutation({
        query: ({ formData, id }) => {
          return {
            url: `/api/update-frequency/${id}`,
            method: "POST",
            body: formData,
          };
        },
        invalidatesTags: ["frequency"],
      }),
      //   Delete a frequency
      deleteModule: builder.mutation({
        query: (id) => {
          return {
            url: `/api/delete_frequency/${id}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["frequency"],
      }),

      //   Get the List of frequencies
      getFrequencies: builder.query({
        query: () => {
          return {
            url: "/api/view-frequency",
            method: "GET",
          };
        },
        providesTags: ["frequency"],
      }),

      //   Get the Single frequency Data
      fetchFrequency: builder.query({
        query: (id) => {
          return {
            url: `/api/view-frequency/${id}`,
            method: "GET",
          };
        },
        providesTags: ["frequency"],
      }),
    };
  },
});

export const {
  useGetFrequenciesQuery,
  useCreateNewFrequencyMutation,
  useDeleteFrequencyMutation,
  useFetchFrequencyQuery,
  useUpdateFrequencyMutation,
} = frequencyService;

export default frequencyService;
