import React from "react";

function FlagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path d="M19.534 2.502A.6.6 0 0119.8 3v9a.6.6 0 01-.377.557L19.2 12l.223.557-.003.001-.007.004-.028.01a26.883 26.883 0 01-1.733.6c-.979.303-2.255.628-3.252.628-1.016 0-1.857-.336-2.59-.63l-.033-.012c-.76-.306-1.409-.558-2.177-.558-.84 0-1.965.276-2.924.573-.43.134-.855.28-1.276.438V21a.6.6 0 11-1.2 0V3a.6.6 0 111.2 0v.338c.271-.095.595-.204.948-.312.98-.3 2.256-.627 3.252-.627 1.008 0 1.829.333 2.545.623l.052.022c.746.301 1.397.555 2.203.555.84 0 1.966-.276 2.925-.572.546-.17 1.086-.361 1.618-.571l.023-.009.005-.002h.001"></path>
    </svg>
  );
}

export default FlagIcon;
